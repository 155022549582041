/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ZZZCharacter } from '../components/zzz-character';

interface IProps {
  name: string;
  character_element?: string;
}

export const ZZZProfileHowTo: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'burnice' && (
        <>
          <p>
            Playing Burnice is incredibly simple, as her only objectives in
            combat are:
          </p>
          <ul>
            <li>Mark/Remark all high priority enemies with “Scorch”.</li>
            <li>
              Maintain consistent uptime of “Scorch” on enemies by ensuring she
              has “Heat” for the majority of the fight through well timed
              EX-Special Attacks and Ultimates.
            </li>
            <li>Ensure energy and Decibels don’t overcap for too long.</li>
            <li>
              Grant as much field time to your other two teammates as possible.
            </li>
            <li>
              Optionally when convenient make use of Dodge Counter, Quick
              Assists, Defensive Assists and Assist Follow-Ups for additional
              Anomaly application and damage.
            </li>
          </ul>
          <h6>
            <strong>SUSTAINING HEAT TIPS</strong>
          </h6>
          <p>
            When considering “Heat” generation it’s important not to waste any
            or run out of it for extended periods of time. Tere are a few things
            to note:
          </p>
          <ul>
            <li>
              A fully channeled EX-Special Attack: Intense Heat Stirring Method
              - Double Shot generates roughly 84 “Heat” (almost a full gauge).
            </li>
            <li>Bernice’s Ultimate generates 50 “Heat” (half a gauge).</li>
            <li>
              Bernice’s enhanced Basic Attack: Mixed Flame Blend consumes up to
              20 “Heat” - using it too frequently at high “Heat” levels can
              starve her of resources causing “Scorch” and Afterburn to have
              significant downtime (Strategic use advised). However, using this
              ability if Burnice is already low on “Heat” (~1-7 “Heat”) is a
              good way to reduce expenditure while also gaining access to its
              benefits as long as you can refill right after.
            </li>
          </ul>
          <p>As a general rule:</p>
          <ul>
            <li>
              Use EX-Special Attack: Intense Heat Stirring Method - Double Shot
              when “Heat” is between 0 - 15%.
            </li>
            <li>Use Ultimate when “Heat” is between 0 - 50%.</li>
            <li>
              Use Basic Attack: Mixed Flame Blend if you have excess field-time
              and “Heat” or you have very little “Heat” remaining and available
              field-time.
            </li>
          </ul>
          <h6>COMBAT OPENER TIPS</h6>
          <p>
            At the beginning of combat Burnice starts in the Nitro-Fuel Cocktail
            state and with 100 “Heat” - use this to your advantage. Start off by
            scorching enemies with the use of Basic Attack: Mixed Flame Blend
            instead of overcapping your “Heat” with an immediate EX use.
          </p>
          <p>
            After, you can follow up immediately with EX-Special Attack: Intense
            Heat Stirring Method - Double Shot. For maximum damage, or
            alternatively Swap-Out Burnice to conserve “Heat” and allow
            Afterburn to do its thing.
          </p>
        </>
      )}
      {name === 'caesar' && (
        <>
          <p>
            Caesar’s goal in battle is to maintain her Core Passive and
            Additional Ability at all times while inflicting as much Daze as
            possible in as little field time as possible. Fortunately, all of
            this can be achieved easily via two simple combos.
          </p>
          <p>
            <strong>Defensive Assist Core Combo:</strong>
          </p>
          <blockquote>
            <ul>
              <li>Enemy Attacks while Caesar is not on field,</li>
              <li>Quick-Swap to Caesar,</li>
              <li>
                Defensive Assist: Aegis Shield (Activates Additional Ability:
                Battle Spirit),
              </li>
              <li>Assist Follow-Up: Aiding Blade (Gain Retaliation),</li>
              <li>
                EX Special Attack: Overpowered Shield Bash (Activates Core
                Ability: Radiant Aegis),
              </li>
              <li>Swap Back to Main Field Character of choice.</li>
            </ul>
          </blockquote>
          <p>
            This combo is her strongest - as it activates both her Core Passive
            and Additional Ability, takes minimal field time, deals tremendous
            Daze per second at cost of 1 Assist Point and 20 Energy, making it
            consistent and economical to execute in most scenarios. This combo
            is suitable when playing her as a Support or as a Stunner alongside
            teammates who are more hungry for field time.
          </p>
          <p>
            Alternatively if the enemy isn’t attacking or you wish to make use
            of Defensive Assist on other characters the following combo can be
            used:
          </p>
          <p>
            <strong>Non-Defensive Assist Combo:</strong>
          </p>
          <blockquote>
            <ul>
              <li>
                EX Special Attack: Parry Counter (Activates Core Ability:
                Radiant Aegis)
              </li>
              <li>EX Special Attack: Overpowered Shield Bash</li>
              <li>
                Basic Attack: Dead End (Activates Additional Ability: Battle
                Spirit)
              </li>
            </ul>
          </blockquote>
          <p>
            This combo can be utilized anytime and doesn't rely on enemies
            attacking into a Defensive Assist making it useful in a pinch and
            for teams that prefer triggering Defensive Assist on other
            characters. However, unlike the Defensive Assist combo, this combo
            is much easier to time and land a Perfect Block with, and if done,
            you can skip using Basic Attack - Dead End as the Perfect Block will
            apply the Additional Ability instead, saving an Assist Point and
            field time.
          </p>
          <p>
            If Caesar is approaching maximum Energy it’s recommended you execute
            a quick Double EX Special Attack combo for extra Daze to ensure you
            don’t waste resources as long as it doesn’t interrupt your team's
            game plan.
          </p>
          <blockquote>
            <ul>
              <li>EX Special Attack: Parry Counter</li>
              <li>EX Special Attack: Overpowered Shield Bash</li>
            </ul>
          </blockquote>
          <p>
            At all times whenever possible it’s always beneficial to attempt
            Perfect Blocks with eligible abilities. Outside of this and what
            we’ve covered Caesar has quite a few other tricks, interactions and
            potential combos at her disposal but in most teams and combat
            situations sticking to the basics should be for the most part
            sufficient.{' '}
          </p>
        </>
      )}
      {name === 'jane-doe' && (
        <>
          <p>
            Playing Jane Doe revolves less around following a strict rotation
            and more about following a set of priorities that allow for
            flexibility. Since Jane's playstyle heavily depends on her team's
            composition, enemy actions, and, more importantly, the management of
            her Passion gauge, you need to master all those elements to play her
            well!
          </p>
          <p>
            The crux of Jane’s gameplay is entering and maintaining the Passion
            State for as long as possible while executing high Anomaly build-up
            combos. When an enemy is Stunned or when Jane's Ultimate is ready,
            this task becomes much easier - thanks to the Chain Attack and
            Ultimate’s ability to instantly reset the Passion State. However,
            outside these windows, managing Jane’s Passion Meter becomes
            important, as any time spent outside the Passion State results in a
            noticeable drop in her damage output.
          </p>
          <p>
            Given this dynamic, Jane’s gameplay can be divided into Burst
            (usually during a stun) and Non-Burst (filling time until the next
            stun) phases, each with distinct goals and priorities.
          </p>
          <h6>Jane Stun Gameplay Priorities</h6>
          <p>
            Since Jane can guarantee one or multiple Passion State resets during
            an enemy stun (especially if using her Ultimate), it’s wise to save
            her burst damage skills for these moments. This ensures not only
            guaranteed Passion State but also maximizes her damage output, as
            Stunned enemies take additional damage, and most team buffs are
            likely to be active (e.g., Swing Jazz, On Stun or Chain Attack
            Debuffs).
          </p>
          <p>
            When looking to deal maximum burst damage with Jane while the enemy
            is Stunned here are some priorities to look out for which can
            dramatically increase your team's damage output.
          </p>
          <p>
            <strong>Do at all times:</strong>
          </p>
          <ul>
            <li>
              <strong>Apply Non-Physical Anomaly Build-Up:</strong> Whenever
              possible, apply non-Physical Anomalies without hogging too much
              field time to trigger Disorders, especially if Jane is the sole
              DPS.
            </li>
            <li>
              <strong>Manage W-Engine Buffs:</strong> Ensure any W-Engine buffs
              relevant to Jane’s damage are stacked or refreshed, particularly
              her Signature buff if you’re using it.
            </li>
          </ul>
          <p>
            <strong>Part 1: Right Before Stun</strong>
          </p>
          <ul>
            <li>
              <strong>Activate Team Buffs:</strong> Trigger any team buffs that
              cannot be activated during Chain Attacks.
            </li>
          </ul>
          <p>
            <strong>Part 2: Chain Attacks</strong>
          </p>
          <ul>
            <li>
              <strong>Utilize Jane’s Chain Attack:</strong> Always use Jane’s
              Chain Attack to ensure a Passion State reset. Include any
              supportive Chain Attacks that amplify damage.
            </li>
            <li>
              <strong>Optimize Buff/Debuff Uptime:</strong> Play around
              team-based Chain Attack buffs to maximize uptime, such as ending
              the Chain with Rina if she’s on the team, to prolong her
              buff/debuff effects.
            </li>
          </ul>
          <p>
            <strong>Part 3: Burst</strong>
          </p>
          <ul>
            <li>
              <strong>Spend Energy Wisely:</strong> Use all saved energy on EX
              Special Attacks, provided it doesn’t force you out of the Passion
              State.
            </li>
            <li>
              <strong>Leverage Basic Attack:Salchow Jump</strong> : Always use
              Basic Attack: Salchow Jump before exiting or refreshing the
              Passion State, as it’s Jane’s strongest non-Chain, non-Ultimate,
              or non-EX move.
            </li>
            <li>
              <strong>Consider Ultimate Timing:</strong> If other characters
              don’t require the Ultimate, use Jane’s Ultimate while the enemy is
              Stunned and has minimal Physical Anomaly build-up. This timing can
              nearly max out the Anomaly gauge and even overcap it, preventing
              damage wastage.
            </li>
          </ul>
          <p>
            Here’s what a burst rotation for a team with Jane as the sole DPS
            might look like against a Boss-type enemy:
          </p>
          <blockquote>
            <ul>
              <li>Chain Attack: Flowers of Sin (Passion)</li>
              <li>
                Dash Attack: Phantom Thrust (Passion) (Optional if not using
                signature)
              </li>
              <li>Basic Attack: Salchow Jump (Passion)</li>
              <li>EX Special Attack: Aerial Sweep - Clearout (Passion)</li>
              <li>EX Special Attack: Aerial Sweep - Clearout (Passion)</li>
              <li>
                Dash Attack: Phantom Thrust (Passion)(Optional if not using
                signature)
              </li>
              <li>Ultimate: Final Curtain (Passion)</li>
              <li>Basic Attack: Salchow Jump (Passion)</li>
            </ul>
          </blockquote>
          <p>
            When using Disorder-based teams, remember to leverage your other
            Anomaly characters to trigger Disorders during the burst window.
            Weaving in Basic or Dash Attacks before executing major attacks
            (like EX Specials or the Ultimate) is advisable if the Physical
            Anomaly build-up bar is close to full. This ensures no Anomaly
            damage potential is wasted, maximizing the overall output.
          </p>
          <h6>Non-Stun Gameplay Priorities</h6>
          <p>
            When playing Jane outside the stun window, her effectiveness depends
            on the team composition and how much field time they 'steal' from
            her.
          </p>
          <p>
            The primary goal for Jane during non-Stun phases is to execute as
            many Dodges, Dodge Counters, and Passion/Anomaly-efficient combos as
            possible. But at the same time, you need to allow her teammates to
            provide buffs etc for her.
          </p>
          <p>
            Here’s a priority list to help guide your decisions when controlling
            Jane during non-stun gameplay:
          </p>
          <ul>
            <li>
              <strong>Support Your Team:</strong> Give the teammates enough
              field time to expend their energy, apply buffs, debuffs, and
              execute their rotations. For example, Qingyi might need to apply
              her Stun damage multiplier before the enemy is Stunned, and Seth
              may need to buff Jane via a Quick Assist.
            </li>
            <li>
              <strong>Apply Non-Physical Anomaly Build-Up:</strong> Without
              interfering with your team's actions, apply as much non-Physical
              Anomaly build-up as possible. This step is optional if Jane is the
              sole DPS.
            </li>
            <li>
              <strong>Manage W-Engine Buffs:</strong> Ensure any W-Engine buffs
              relevant to Jane’s damage are stacked or refreshed. Pay particular
              attention to Jane’s Signature buff if you're using it.
            </li>
            <li>
              <strong>Conserve Jane’s Energy:</strong> Save Jane’s energy for
              when the enemy is Stunned, as this is when you can maximize her
              damage output.
            </li>
            <li>
              <strong>Maximize Jane’s Field Time:</strong> When Jane has the
              field, prioritize her actions based on the list below to maximize
              her efficiency.
            </li>
          </ul>
          <p>
            Execute the following in order of priority whenever Jane gets field
            time outside the Stun window.
          </p>
          <blockquote>
            <ul>
              <li>
                Use Basic Attack: Salchow Jump when Passion is below 75% and a
                charge is available - optionally follow-up with the following
                combo by tapping Basic Attack right after Salchow Jump:
              </li>
              <ul>
                <li>During Passion State:</li>
                <ul>
                  <li>Basic Attack: Salchow Jump (Passion)</li>
                  <li>
                    Dash Attack: Phantom Thrust (Automatically cast by tapping
                    basic)
                  </li>
                  <li>Basic Attack: Dancing Blades P4 (Passion)</li>
                  <li>Basic Attack: Dancing Blades P5 (Passion)</li>
                  <li>Basic Attack: Dancing Blades P6 (Passion)</li>
                </ul>
              </ul>
              <li>
                Dodge Counter Combo (Whenever possible, interrupt any Basic
                Attack combo to perform more Dodge Counters if possible):
              </li>
              <ul>
                <li>To Build Passion:</li>
                <ul>
                  <li>Dodge Counter: Swift Shadow</li>
                  <li>Basic Attack: Dancing Blades P3</li>
                  <li>Basic Attack: Dancing Blades P4</li>
                  <li>Basic Attack: Dancing Blades P5</li>
                  <li>Basic Attack: Dancing Blades P6</li>
                </ul>
                <li>To Spend Passion:</li>
                <ul>
                  <li>Dodge Counter: Swift Shadow Dance (Passion)</li>
                  <li>Basic Attack: Dancing Blades P5 (Passion)</li>
                  <li>Basic Attack: Dancing Blades P6 (Passion)</li>
                </ul>
              </ul>
              <li>
                Fill Field Time with Dash Attacks/Dash Attack Combos (if using
                Signature and buff requires refreshing) or Standard Basic Attack
                Combos while waiting for additional opportunities to execute
                Dodge Counters or Salchow Jumps:
              </li>
              <li>
                Dodge Counter Combo (Whenever possible, interrupt any Basic
                Attack combo to perform more Dodge Counters if possible):
              </li>
              <ul>
                <li>
                  Dash Attack Non-Passion State (Only used to refresh
                  signature):
                </li>
                <ul>
                  <li>Dash Attack: Phantom Thrust (Passion)</li>
                  <li>Basic Attack: Dancing Blades P4 (Passion)</li>
                  <li>Basic Attack: Dancing Blades P5 (Passion)</li>
                  <li>Basic Attack: Dancing Blades P6 (Passion)</li>
                </ul>
                <li>
                  Dash Attack Passion State (Used for Mobility and/or to Refresh
                  Signature):
                </li>
                <ul>
                  <li>
                    Basic Attack: Dancing Blades P1 (Passion or Non-Passion)
                  </li>
                  <li>
                    Basic Attack: Dancing Blades P2 (Passion or Non-Passion)
                  </li>
                  <li>
                    Basic Attack: Dancing Blades P3 (Passion or Non-Passion)
                  </li>
                  <li>
                    Basic Attack: Dancing Blades P4 (Passion or Non-Passion)
                  </li>
                  <li>
                    Basic Attack: Dancing Blades P5 (Passion or Non-Passion)
                  </li>
                  <li>
                    Basic Attack: Dancing Blades P6 (Passion or Non-Passion)
                  </li>
                </ul>
              </ul>
            </ul>
          </blockquote>
        </>
      )}
      {name === 'qingyi' && (
        <>
          <p>
            Despite Qingyi's complex kit, her gameplay is surprisingly
            straightforward, focusing on a few key combos and a loose priority
            order. The essence of playing Qingyi lies in accumulating and
            spending Voltage on her Enhanced Charged Basic attacks to apply
            Subjugation stacks effectively. To maximize her impact, it’s crucial
            to execute the full Enhanced Charged Basic attack with all five dash
            attacks, unless you’re confident in triggering the bonus dodge with
            the final blow.
          </p>
          <p>
            Qingyi's gameplay centers around two primary combos, each tailored
            to specific battle conditions.
          </p>
          <p>
            This combo is optimal when you have enough energy to execute an EX
            attack, allowing you to maximize Qingyi's damage and Daze potential:
          </p>
          <blockquote>
            <ul>
              <li>EX Special Attack: Moonlit Begonia</li>
              <li>
                Basic Attack: Penultimate P3 (Tap until empowered P4 is
                available and Voltage is close to 75)
              </li>
              <li>Basic Attack: Penultimate P4 (Empowered)</li>
              <li>
                Basic Attack: Enchanted Moonlit Blossoms (Consumes 75 Voltage)
              </li>
            </ul>
          </blockquote>
          <p>
            This combo is ideal when you don't have enough energy for an EX but
            can rely on a Dodge Counter to initiate the sequence. It requires a
            bit more time spent tapping Basic P3.
          </p>
          <blockquote>
            <ul>
              <li>EX Special Attack: Moonlit Begonia</li>
              <li>
                Basic Attack: Penultimate P3 (Tap until empowered P4 is
                available and Voltage is close to 75)
              </li>
              <li>Basic Attack: Penultimate P4 (Empowered)</li>
              <li>
                Basic Attack: Enchanted Moonlit Blossoms (Consumes 75 Voltage)
              </li>
            </ul>
          </blockquote>
          <p>
            If you’re out of energy for an EX and lack an opportunity for a
            Dodge Counter, you can fall back on a standard combo:
          </p>
          <blockquote>
            <ul>
              <li>Basic Attack: Penultimate P1</li>
              <li>Basic Attack: Penultimate P2</li>
              <li>
                Basic Attack: Penultimate P3 (Tap until empowered P4 is
                available and Voltage is close to 75)
              </li>
              <li>Basic Attack: Penultimate P4 (Empowered)</li>
              <li>
                Basic Attack: Enchanted Moonlit Blossoms (Consumes 75 Voltage)
              </li>
            </ul>
          </blockquote>
          <p>
            This combo is less efficient, as the first two attacks don’t
            generate Voltage, deal less Daze, and offer lower damage output. It
            should only be used to fill time between Dodge Counters and EX
            Specials.
          </p>
          <p>
            When you have an abundance of energy or need to Stun an enemy
            quickly, you can hold down Qingyi’s EX for a burst of high damage,
            Voltage and Daze per second but makes you more reliant on Dodge
            Counters or the Standard combo as you’ll have less energy to work
            with.
          </p>
          <p>
            When faced with a flurry of opportunities to perform Dodge Counters
            and/or you find yourself with a tonne of energy, the EX and Dodge
            counter combo can be combined to shoot for a very high Voltage
            finisher with minimal Basic 3 tapping time required.
          </p>
          <p>
            For a High Voltage & Daze Combo using both EX and Dodge Counter:
          </p>
          <blockquote>
            <ul>
              <li>Dodge Counter: Lingering Sentiments</li>
              <li>
                Basic Attack: Penultimate P3 (Tap only until empowered P4
                available)
              </li>
              <li>Basic Attack: Penultimate P4 (Empowered)</li>
              <li>EX Special Attack: Moonlit Begonia</li>
              <li>
                Basic Attack: Enchanted Moonlit Blossoms (Consumes 85 -100
                Voltage)
              </li>
            </ul>
          </blockquote>
          <p>
            This combo offers a powerful high Voltage finisher with minimal
            Basic P3 tapping. However, it’s important to remember that the
            difference between a 100 Voltage Charged Enhanced Basic and a 75
            Voltage one isn’t significant. Therefore, don’t delay stacking
            Subjugation just to gain a slight Daze increase unless the resources
            and situation align perfectly.
          </p>
        </>
      )}
      {name === 'zhu-yuan' && (
        <>
          <p>
            Despite having an incredibly complex kit with many possibilities,
            Zhu Yuan is very easy to play when you know which combos to execute.
            She is almost entirely focused on Burst Damage, which should be
            delivered while the enemy is Stunned to take advantage of her Core
            Passive and the Stun damage multiplier. Our goal is to build and
            unload as many Shell Enhanced Suppressive Mode Basic Attacks as
            possible within the Stun window.
          </p>
          <p>
            There are many sequences of Suppressive Mode Basics, EXs, and her
            Ultimate you can execute while the enemy is Stunned, but here is the
            combo we found to be most optimal, dealing the most damage in the
            least time. Before executing this combo, we recommend holding at
            least 2 Shells minimum (M0) in reserve to achieve maximum burst:
          </p>
          <blockquote>
            <ul>
              <li>Start (2 Shells)</li>
              <li>Chain Attack (5 Shells)</li>
              <li>Quick Assist: After Nicole (6 Shells)</li>
              <li>
                EX Special Attack (Remove if not enough energy) (9 Shells)
              </li>
              <li>Suppressive Mode Basic Attack P1 (8 Shells)</li>
              <li>Suppressive Mode Basic Attack P2 (7 Shells)</li>
              <li>Suppressive Mode Basic Attack P3 (4 Shells)</li>
              <li>Suppressive Mode Side Dash Attack (3 Shells)</li>
              <li>Suppressive Mode Basic Attack P3 (0 Shells)</li>
              <li>EX Special Attack (3 Shells)</li>
              <li>Ultimate (6 Shells)</li>
              <li>Suppressive Mode Basic Attack P1 (5 Shells)</li>
              <li>Suppressive Mode Basic Attack P2 (4 Shells)</li>
              <li>Suppressive Mode Basic Attack P3 (1 Shells)</li>
              <li>Suppressive Mode Side Dash Attack (0 Shells)</li>
            </ul>
          </blockquote>
          <p>
            As you can see, assuming you start with 2 Shells with Zhu Yuan’s
            Energy gauge full, and her Ultimate available, you can unload a
            total of 15 Shells into a Stunned enemy incredibly quickly. In case
            you don’t have Zhu Yuan’s Ultimate or one of the EX’s available, you
            can offset this by either building up additional Shells before
            starting combo (5 instead of 2 to replace 1 EX or Ultimate) before
            the Stun window or cutting short her burst rotation. Also, when
            using certain W-Engines, Disc Drives, or Team Compositions, swapping
            the first EX in above rotation with the Ultimate can lead to
            increased damage for little downside (this is true if playing Zhu
            Yuan with Nicole and you don’t intend to quick swap Nicole in to
            refresh her debuffs).
          </p>
          <p>
            Outside of Zhu Yuan’s Stun burst rotations her damage is
            underwhelming unless you choose to expend EX’s and Shells (Only
            recommended against Non-Boss enemies). For this reason against any
            tough enemies your goal should be to recharge Energy, Shells and
            Decibels and get back to bursting as fast as possible. There are two
            options to choose from when aiming to achieve this.
          </p>
          <p>
            You can keep Zhu Yuan on field and focus on performing Assault Mode
            combos (Listed Below) that yield the most Shells, the Defensive
            Assist of your Stun character and Zhu Yuan’s Dodge Counters where
            they make sense.
          </p>
          <blockquote>
            <p>
              <strong>Standard combo:</strong>
            </p>
            <ul>
              <li>Assault Mode Basic P1</li>
              <li>Side Dash Attack</li>
              <li>Assault Mode Basic P3</li>
              <li>Assault Mode Basic P4</li>
              <li>Assault Mode Basic P5</li>
              <li>Side Dash Attack</li>
              <li>Assault Mode Basic P3</li>
            </ul>
          </blockquote>
        </>
      )}
      {name === 'ellen' && (
        <>
          <p>
            Ellen, like most combat characters, has a Burst Rotation she aims to
            execute while the boss is Stunned and a Standard Rotation she uses
            against smaller enemies not worthy of her full burst potential or to
            use during any downtime she has (waiting to Stun the enemy again,
            dealing with foes who die even before being Stunned or to fill extra
            time during a Stun window if you don’t have enough energy to execute
            a full combo).
          </p>
          <p>
            In both rotations, you’ll be using Ellen’s Basic Attacks a lot and
            it’s imperative you only do so when you have Flash Freeze charges to
            consume to empower them. Here is a comparison of what 3 un-enhanced
            Basic Attacks deal against an enemy compared to 3 Enhanced Basic
            Attacks both with a full endgame build.
          </p>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Ellen Basic Attack comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Empowered Basic Attack</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">179,950 DMG</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Un-empowered Basic Attack</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '22.64%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">40,749 DMG</span>
                          <span className="percent">22.64%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h6>Standard rotation</h6>
          <p>
            Ellen’s standard rotation doesn’t require the use of any energy or
            your team's Ultimates, making it ideal for building and conserving
            energy when you have downtime and are getting ready for your next
            burst rotation while you build Daze to Stun the enemy. Ellen’s most
            optimal standard rotation consists of a fully charged Arctic Assault
            to generate 3 Flash Freeze charges followed by 3 Enhanced Basic
            Attacks, repeat. Your sequence of attack should look like this:
          </p>
          <blockquote>
            <ul>
              <li>Dash Attack: Arctic Ambush: Spinning Slash </li>
              <li>Dash Attack: Arctic Ambush: Charged Scissors </li>
              <li>Basic Attack: Flash Freeze Trimming P1 </li>
              <li>Basic Attack: Flash Freeze Trimming P2 </li>
              <li>Basic Attack: Flash Freeze Trimming P3</li>
            </ul>
          </blockquote>
          <p>
            While executing Ellen’s standard rotation if you find yourself full
            on energy don’t waste it by over-capping it. Instead spend it on an
            EX Special followed by a Basic 3. This is also a valid tactic
            against enemies close enough to death as Stunning them again would
            be slower than taking them out earlier with the use of Ellen’s
            energy.
          </p>
          <blockquote>
            <ul>
              <li>EX Special Attack: Tail Swipe </li>
              <li>Basic Attack: Flash Freeze Trimming P3</li>
            </ul>
          </blockquote>
          <h6>Burst rotation</h6>
          <p>
            Ellen’s Burst Rotation comes into play after you Stun an enemy and
            is what we recommend you save your team's Decibels and Ellen’s
            energy for in order to deal maximum damage. As the enemy is only
            Stunned for a short while, charging Arctic Assault and executing a
            full sequence of Basic Attack 1, 2 and 3 isn’t time efficient.
            Instead we want to make full use of Ellen’s EX + Basic 3 combo and
            string together as many of these alongside her Ultimate as possible.
            Assuming Ellen has a full energy gauge (120 energy) and 3000
            Decibels available an optimal burst combo should look similar to
            this:
          </p>
          <blockquote>
            <ul>
              <li>Chain Attack: Avalanche </li>
              <li>EX Special Attack: Tail Swipe </li>
              <li>Basic Attack: Flash Freeze Trimming P3 </li>
              <li>Ultimate: Endless Winter </li>
              <li>EX Special Attack: Tail Swipe </li>
              <li>EX Special Attack: Sharknami </li>
              <li>Basic Attack: Flash Freeze Trimming P3</li>
            </ul>
          </blockquote>
          <p>
            Or alternatively, if you’re okay with your burst combo being longer
            but dealing more damage (higher overall damage, lower dps) you can
            also utilize a combo similar to this one instead:
          </p>
          <blockquote>
            <ul>
              <li>Chain Attack: Avalanche </li>
              <li>EX Special Attack: Tail Swipe </li>
              <li>Basic Attack: Flash Freeze Trimming P3 </li>
              <li>Ultimate: Endless Winter </li>
              <li>EX Special Attack: Tail Swipe </li>
              <li>Basic Attack: Flash Freeze Trimming P3 </li>
              <li>EX Special Attack: Tail Swipe </li>
              <li>Basic Attack: Flash Freeze Trimming P3</li>
            </ul>
          </blockquote>
          <p>
            In each of these combos, Ellen is dealing the maximum possible
            damage in the shortest possible time by burning through her and her
            teams resources, but if you go in without enough energy to unleash a
            full combo or without Ellen’s Ultimate, skip what you can’t do and
            revert to Ellen’s standard rotation for the remaining time the enemy
            is vulnerable.
          </p>
          <p>
            It’s important to note that depending on the W-engine and Disc Drive
            set you’re using you may want to re-order the use of certain
            abilities when executing Ellen's Burst rotation. A good example of
            this would be you would want to use Ellen’s Ultimate as fast as
            possible while using Puffer Electro to activate its buff but would
            want to delay the Ultimate until after using an EX and a Basic
            attack when using Woodpecker Electro.
          </p>
          <p>
            As you continue to play Ellen more you’ll learn when to save her
            resources for a big burst combo or burn them all early to take out
            higher priority smaller enemies. Ultimately the best Ellen players
            make full use of her kit by combining both her rotations using all
            or parts of each as the situation demands it.
          </p>
        </>
      )}
    </>
  );
};
