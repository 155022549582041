export const ShiyuDuos = [
  {
    char: 'Ben',
    round: 2.64,
    char_1: 'Lucy',
    app_rate_1: 51.61,
    avg_round_1: 2.64,
    app_flat_1: 0,
    char_2: 'soldier-11',
    app_rate_2: 44.09,
    avg_round_2: 2.71,
    app_flat_2: 0,
    char_3: 'Koleda',
    app_rate_3: 23.66,
    avg_round_3: 2.55,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 17.2,
    avg_round_4: 2.8,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 17.2,
    avg_round_5: 2.75,
    app_flat_5: 0,
    char_6: 'grace-howard',
    app_rate_6: 6.45,
    avg_round_6: 2.83,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 6.45,
    avg_round_7: 2.83,
    app_flat_7: 0,
    char_8: 'Anton',
    app_rate_8: 5.38,
    avg_round_8: 2.4,
    app_flat_8: 0,
    char_9: 'Caesar',
    app_rate_9: 4.3,
    avg_round_9: 2.5,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 4.3,
    avg_round_10: 2.25,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 4.3,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 3.23,
    avg_round_12: 2.5,
    app_flat_12: 0,
    char_13: 'billy-kid',
    app_rate_13: 2.15,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Ellen',
    app_rate_14: 2.15,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Yanagi',
    app_rate_15: 2.15,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Qingyi',
    app_rate_16: 2.15,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'Corin',
    app_rate_17: 1.08,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: 'Soukaku',
    app_rate_18: 1.08,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 1.08,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    round: 2.64,
    char_1: 'Caesar',
    app_rate_1: 57.75,
    avg_round_1: 2.72,
    app_flat_1: 0,
    char_2: 'nicole-demara',
    app_rate_2: 45.07,
    avg_round_2: 2.63,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 25.35,
    avg_round_3: 2.53,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 16.9,
    avg_round_4: 2.67,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 15.49,
    avg_round_5: 2.62,
    app_flat_5: 0,
    char_6: 'Piper',
    app_rate_6: 9.86,
    avg_round_6: 2.43,
    app_flat_6: 0,
    char_7: 'Burnice',
    app_rate_7: 7.04,
    avg_round_7: 2.75,
    app_flat_7: 0,
    char_8: 'Ben',
    app_rate_8: 2.82,
    avg_round_8: 2,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 2.82,
    avg_round_9: 2.5,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 2.82,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Koleda',
    app_rate_11: 2.82,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'Anton',
    app_rate_12: 1.41,
    avg_round_12: 1,
    app_flat_12: 0,
    char_13: 'Lycaon',
    app_rate_13: 1.41,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Seth',
    app_rate_14: 1.41,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Corin',
    app_rate_15: 1.41,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'Soukaku',
    app_rate_16: 1.41,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Yanagi',
    app_rate_17: 1.41,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Corin',
    round: 2.67,
    char_1: 'Caesar',
    app_rate_1: 42.86,
    avg_round_1: 2.8,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 34.45,
    avg_round_2: 2.74,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 26.05,
    avg_round_3: 2.75,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 19.33,
    avg_round_4: 2.71,
    app_flat_4: 0,
    char_5: 'Lycaon',
    app_rate_5: 16.81,
    avg_round_5: 2.5,
    app_flat_5: 0,
    char_6: 'Piper',
    app_rate_6: 15.13,
    avg_round_6: 2.62,
    app_flat_6: 0,
    char_7: 'Soukaku',
    app_rate_7: 11.76,
    avg_round_7: 2.5,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 11.76,
    avg_round_8: 2.45,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 4.2,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Burnice',
    app_rate_10: 3.36,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'Koleda',
    app_rate_11: 3.36,
    avg_round_11: 2.75,
    app_flat_11: 0,
    char_12: 'anby-demara',
    app_rate_12: 2.52,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 2.52,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Ellen',
    app_rate_14: 1.68,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 0.84,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.84,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.84,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.84,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Anton',
    round: 2.73,
    char_1: 'Qingyi',
    app_rate_1: 53.12,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 49.11,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'grace-howard',
    app_rate_3: 33.04,
    avg_round_3: 2.81,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 21.88,
    avg_round_4: 2.62,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 13.84,
    avg_round_5: 2.83,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 8.04,
    avg_round_6: 2.69,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 6.25,
    avg_round_7: 2.14,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 3.57,
    avg_round_8: 2.25,
    app_flat_8: 0,
    char_9: 'Burnice',
    app_rate_9: 2.23,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Ben',
    app_rate_10: 2.23,
    avg_round_10: 2.4,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 1.34,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Lycaon',
    app_rate_12: 1.34,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'Soukaku',
    app_rate_13: 1.34,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Yanagi',
    app_rate_14: 1.34,
    avg_round_14: 2.5,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 0.45,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.45,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Koleda',
    round: 2.77,
    char_1: 'Lucy',
    app_rate_1: 37.91,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'soldier-11',
    app_rate_2: 33.52,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 18.41,
    avg_round_3: 2.78,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 17.31,
    avg_round_4: 2.76,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 13.46,
    avg_round_5: 2.8,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 13.46,
    avg_round_6: 2.82,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 12.91,
    avg_round_7: 2.81,
    app_flat_7: 0,
    char_8: 'Burnice',
    app_rate_8: 11.26,
    avg_round_8: 2.8,
    app_flat_8: 0,
    char_9: 'Piper',
    app_rate_9: 9.07,
    avg_round_9: 2.73,
    app_flat_9: 0,
    char_10: 'Caesar',
    app_rate_10: 8.52,
    avg_round_10: 2.52,
    app_flat_10: 0,
    char_11: 'Ben',
    app_rate_11: 6.04,
    avg_round_11: 2.55,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 5.22,
    avg_round_12: 2.65,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 2.75,
    avg_round_13: 2.78,
    app_flat_13: 0,
    char_14: 'Anton',
    app_rate_14: 2.2,
    avg_round_14: 2.25,
    app_flat_14: 0,
    char_15: 'Rina',
    app_rate_15: 2.2,
    avg_round_15: 2.86,
    app_flat_15: 0,
    char_16: 'Yanagi',
    app_rate_16: 1.92,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 1.1,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 1.1,
    avg_round_18: 2.75,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.55,
    avg_round_19: 2.5,
    app_flat_19: 0,
    char_20: 'Lycaon',
    app_rate_20: 0.27,
    avg_round_20: 1,
    app_flat_20: 0,
    char_21: 'Qingyi',
    app_rate_21: 0.27,
    avg_round_21: 1,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    round: 2.78,
    char_1: 'nicole-demara',
    app_rate_1: 54.91,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 46.01,
    avg_round_2: 2.87,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 16.1,
    avg_round_3: 2.7,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 15.64,
    avg_round_4: 2.75,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 11.96,
    avg_round_5: 2.83,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 9.82,
    avg_round_6: 2.75,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 8.59,
    avg_round_7: 2.63,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 7.82,
    avg_round_8: 2.74,
    app_flat_8: 0,
    char_9: 'Anton',
    app_rate_9: 7.52,
    avg_round_9: 2.62,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 5.83,
    avg_round_10: 2.67,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 2.76,
    avg_round_11: 2.65,
    app_flat_11: 0,
    char_12: 'Burnice',
    app_rate_12: 2.61,
    avg_round_12: 2.86,
    app_flat_12: 0,
    char_13: 'Nekomata',
    app_rate_13: 2.15,
    avg_round_13: 2.64,
    app_flat_13: 0,
    char_14: 'Caesar',
    app_rate_14: 1.84,
    avg_round_14: 2.78,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 1.84,
    avg_round_15: 2.67,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 1.53,
    avg_round_16: 2.7,
    app_flat_16: 0,
    char_17: 'Qingyi',
    app_rate_17: 1.23,
    avg_round_17: 2.38,
    app_flat_17: 0,
    char_18: 'Piper',
    app_rate_18: 0.92,
    avg_round_18: 2.5,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.46,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: 'Lycaon',
    app_rate_20: 0.31,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.15,
    avg_round_21: 2,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ellen',
    round: 2.8,
    char_1: 'Soukaku',
    app_rate_1: 83.58,
    avg_round_1: 2.81,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 53.16,
    avg_round_2: 2.81,
    app_flat_2: 0,
    char_3: 'Qingyi',
    app_rate_3: 19.73,
    avg_round_3: 2.79,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 18.37,
    avg_round_4: 2.92,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 7.68,
    avg_round_5: 2.67,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 6.2,
    avg_round_6: 2.7,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 3.96,
    avg_round_7: 2.78,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 1.65,
    avg_round_8: 2.67,
    app_flat_8: 0,
    char_9: 'Lucy',
    app_rate_9: 0.95,
    avg_round_9: 2.85,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 0.89,
    avg_round_10: 2.7,
    app_flat_10: 0,
    char_11: 'Burnice',
    app_rate_11: 0.83,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 0.47,
    avg_round_12: 2.4,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 0.47,
    avg_round_13: 2.33,
    app_flat_13: 0,
    char_14: 'Yanagi',
    app_rate_14: 0.24,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'jane-doe',
    app_rate_15: 0.18,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.12,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Corin',
    app_rate_17: 0.12,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.12,
    avg_round_18: 2.5,
    app_flat_18: 0,
    char_19: 'Piper',
    app_rate_19: 0.06,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: 'Anton',
    app_rate_20: 0.06,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Soukaku',
    round: 2.8,
    char_1: 'Ellen',
    app_rate_1: 93.21,
    avg_round_1: 2.81,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 49.6,
    avg_round_2: 2.79,
    app_flat_2: 0,
    char_3: 'Qingyi',
    app_rate_3: 19.37,
    avg_round_3: 2.8,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 11.66,
    avg_round_4: 2.96,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 6.72,
    avg_round_5: 2.75,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 4.15,
    avg_round_6: 2.76,
    app_flat_6: 0,
    char_7: 'Yanagi',
    app_rate_7: 3.16,
    avg_round_7: 2.88,
    app_flat_7: 0,
    char_8: 'Rina',
    app_rate_8: 2.83,
    avg_round_8: 2.62,
    app_flat_8: 0,
    char_9: 'Seth',
    app_rate_9: 1.65,
    avg_round_9: 2.96,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 1.58,
    avg_round_10: 2.67,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 1.05,
    avg_round_11: 2.64,
    app_flat_11: 0,
    char_12: 'Corin',
    app_rate_12: 0.92,
    avg_round_12: 2.5,
    app_flat_12: 0,
    char_13: 'Lucy',
    app_rate_13: 0.79,
    avg_round_13: 2.8,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 0.79,
    avg_round_14: 2.91,
    app_flat_14: 0,
    char_15: 'Burnice',
    app_rate_15: 0.59,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 0.26,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.2,
    avg_round_17: 2.67,
    app_flat_17: 0,
    char_18: 'Anton',
    app_rate_18: 0.2,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.2,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: 'Piper',
    app_rate_20: 0.2,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.07,
    avg_round_21: 1,
    app_flat_21: 0,
    char_22: 'billy-kid',
    app_rate_22: 0.07,
    avg_round_22: 3,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lycaon',
    round: 2.8,
    char_1: 'Ellen',
    app_rate_1: 91.09,
    avg_round_1: 2.81,
    app_flat_1: 0,
    char_2: 'Soukaku',
    app_rate_2: 76.21,
    avg_round_2: 2.79,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 12.04,
    avg_round_3: 2.89,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 4.66,
    avg_round_4: 2.78,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 3.85,
    avg_round_5: 2.82,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 2.23,
    avg_round_6: 2.79,
    app_flat_6: 0,
    char_7: 'Corin',
    app_rate_7: 2.02,
    avg_round_7: 2.5,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 1.42,
    avg_round_8: 2.9,
    app_flat_8: 0,
    char_9: 'Lucy',
    app_rate_9: 1.11,
    avg_round_9: 2.64,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 0.91,
    avg_round_10: 2.62,
    app_flat_10: 0,
    char_11: 'soldier-11',
    app_rate_11: 0.81,
    avg_round_11: 2.62,
    app_flat_11: 0,
    char_12: 'Qingyi',
    app_rate_12: 0.71,
    avg_round_12: 2.8,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 0.51,
    avg_round_13: 2.75,
    app_flat_13: 0,
    char_14: 'Seth',
    app_rate_14: 0.4,
    avg_round_14: 2.67,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 0.4,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Burnice',
    app_rate_16: 0.4,
    avg_round_16: 2.67,
    app_flat_16: 0,
    char_17: 'Anton',
    app_rate_17: 0.3,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 0.2,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Koleda',
    app_rate_19: 0.1,
    avg_round_19: 1,
    app_flat_19: 0,
    char_20: 'Piper',
    app_rate_20: 0.1,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.1,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    round: 2.8,
    char_1: 'Lucy',
    app_rate_1: 80.78,
    avg_round_1: 2.81,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 50.33,
    avg_round_2: 2.81,
    app_flat_2: 0,
    char_3: 'Burnice',
    app_rate_3: 22.31,
    avg_round_3: 2.88,
    app_flat_3: 0,
    char_4: 'Koleda',
    app_rate_4: 19.87,
    avg_round_4: 2.78,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 7.17,
    avg_round_5: 2.94,
    app_flat_5: 0,
    char_6: 'Ben',
    app_rate_6: 6.68,
    avg_round_6: 2.71,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 3.42,
    avg_round_7: 2.55,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 2.12,
    avg_round_8: 2.27,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 1.63,
    avg_round_9: 2.7,
    app_flat_9: 0,
    char_10: 'Lycaon',
    app_rate_10: 1.3,
    avg_round_10: 2.62,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 1.14,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 0.65,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 0.33,
    avg_round_13: 2.5,
    app_flat_13: 0,
    char_14: 'Rina',
    app_rate_14: 0.33,
    avg_round_14: 2.5,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 0.33,
    avg_round_15: 2.5,
    app_flat_15: 0,
    char_16: 'Seth',
    app_rate_16: 0.16,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Corin',
    app_rate_17: 0.16,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Nekomata',
    round: 2.8,
    char_1: 'Caesar',
    app_rate_1: 45.56,
    avg_round_1: 2.82,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 43.89,
    avg_round_2: 2.83,
    app_flat_2: 0,
    char_3: 'nicole-demara',
    app_rate_3: 28.33,
    avg_round_3: 2.84,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 27.78,
    avg_round_4: 2.82,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 16.11,
    avg_round_5: 2.78,
    app_flat_5: 0,
    char_6: 'Burnice',
    app_rate_6: 10.0,
    avg_round_6: 2.75,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 9.44,
    avg_round_7: 2.77,
    app_flat_7: 0,
    char_8: 'anby-demara',
    app_rate_8: 7.78,
    avg_round_8: 2.64,
    app_flat_8: 0,
    char_9: 'Seth',
    app_rate_9: 4.44,
    avg_round_9: 2.86,
    app_flat_9: 0,
    char_10: 'Koleda',
    app_rate_10: 2.22,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'Rina',
    app_rate_11: 1.67,
    avg_round_11: 2.67,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 1.67,
    avg_round_12: 2.67,
    app_flat_12: 0,
    char_13: 'grace-howard',
    app_rate_13: 0.56,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 0.56,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    round: 2.82,
    char_1: 'Seth',
    app_rate_1: 29.46,
    avg_round_1: 2.83,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 27.96,
    avg_round_2: 2.89,
    app_flat_2: 0,
    char_3: 'Burnice',
    app_rate_3: 25.81,
    avg_round_3: 2.89,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 25.38,
    avg_round_4: 2.79,
    app_flat_4: 0,
    char_5: 'Anton',
    app_rate_5: 15.91,
    avg_round_5: 2.81,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 14.41,
    avg_round_6: 2.77,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 8.82,
    avg_round_7: 2.92,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 8.6,
    avg_round_8: 2.89,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 8.17,
    avg_round_9: 2.67,
    app_flat_9: 0,
    char_10: 'Caesar',
    app_rate_10: 8.17,
    avg_round_10: 2.82,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 7.1,
    avg_round_11: 2.73,
    app_flat_11: 0,
    char_12: 'Koleda',
    app_rate_12: 4.09,
    avg_round_12: 2.65,
    app_flat_12: 0,
    char_13: 'Piper',
    app_rate_13: 3.87,
    avg_round_13: 2.75,
    app_flat_13: 0,
    char_14: 'zhu-yuan',
    app_rate_14: 3.23,
    avg_round_14: 2.58,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 3.23,
    avg_round_15: 2.7,
    app_flat_15: 0,
    char_16: 'Soukaku',
    app_rate_16: 2.58,
    avg_round_16: 2.91,
    app_flat_16: 0,
    char_17: 'Ben',
    app_rate_17: 1.29,
    avg_round_17: 2.83,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 1.08,
    avg_round_18: 2.75,
    app_flat_18: 0,
    char_19: 'soldier-11',
    app_rate_19: 0.43,
    avg_round_19: 2.5,
    app_flat_19: 0,
    char_20: 'Nekomata',
    app_rate_20: 0.22,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Rina',
    round: 2.85,
    char_1: 'Yanagi',
    app_rate_1: 36.15,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 29.78,
    avg_round_2: 2.88,
    app_flat_2: 0,
    char_3: 'jane-doe',
    app_rate_3: 22.47,
    avg_round_3: 2.89,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 21.63,
    avg_round_4: 2.81,
    app_flat_4: 0,
    char_5: 'Ellen',
    app_rate_5: 13.58,
    avg_round_5: 2.67,
    app_flat_5: 0,
    char_6: 'grace-howard',
    app_rate_6: 13.58,
    avg_round_6: 2.89,
    app_flat_6: 0,
    char_7: 'Burnice',
    app_rate_7: 13.27,
    avg_round_7: 2.93,
    app_flat_7: 0,
    char_8: 'Anton',
    app_rate_8: 11.49,
    avg_round_8: 2.78,
    app_flat_8: 0,
    char_9: 'Caesar',
    app_rate_9: 7.94,
    avg_round_9: 2.91,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 6.69,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 4.81,
    avg_round_11: 2.78,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 4.49,
    avg_round_12: 2.62,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 3.24,
    avg_round_13: 2.73,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 3.24,
    avg_round_14: 2.75,
    app_flat_14: 0,
    char_15: 'Lucy',
    app_rate_15: 2.72,
    avg_round_15: 2.95,
    app_flat_15: 0,
    char_16: 'nicole-demara',
    app_rate_16: 1.36,
    avg_round_16: 2.9,
    app_flat_16: 0,
    char_17: 'Piper',
    app_rate_17: 1.15,
    avg_round_17: 2.82,
    app_flat_17: 0,
    char_18: 'Koleda',
    app_rate_18: 0.84,
    avg_round_18: 2.86,
    app_flat_18: 0,
    char_19: 'Nekomata',
    app_rate_19: 0.31,
    avg_round_19: 2.67,
    app_flat_19: 0,
    char_20: 'billy-kid',
    app_rate_20: 0.21,
    avg_round_20: 2.5,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.21,
    avg_round_21: 2.5,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Piper',
    round: 2.87,
    char_1: 'Lucy',
    app_rate_1: 64.19,
    avg_round_1: 2.88,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 58.16,
    avg_round_2: 2.92,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 38.77,
    avg_round_3: 2.89,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 8.01,
    avg_round_4: 2.79,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 5.14,
    avg_round_5: 2.89,
    app_flat_5: 0,
    char_6: 'Nekomata',
    app_rate_6: 4.95,
    avg_round_6: 2.82,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 3.26,
    avg_round_7: 2.73,
    app_flat_7: 0,
    char_8: 'Seth',
    app_rate_8: 2.97,
    avg_round_8: 2.77,
    app_flat_8: 0,
    char_9: 'soldier-11',
    app_rate_9: 2.08,
    avg_round_9: 2.55,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 1.98,
    avg_round_10: 2.85,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 1.78,
    avg_round_11: 2.75,
    app_flat_11: 0,
    char_12: 'Corin',
    app_rate_12: 1.78,
    avg_round_12: 2.62,
    app_flat_12: 0,
    char_13: 'Ben',
    app_rate_13: 1.58,
    avg_round_13: 2.75,
    app_flat_13: 0,
    char_14: 'Qingyi',
    app_rate_14: 1.19,
    avg_round_14: 2.78,
    app_flat_14: 0,
    char_15: 'Rina',
    app_rate_15: 1.09,
    avg_round_15: 2.82,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.69,
    avg_round_16: 2.43,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 0.59,
    avg_round_17: 2.5,
    app_flat_17: 0,
    char_18: 'Soukaku',
    app_rate_18: 0.3,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Lycaon',
    app_rate_19: 0.1,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Ellen',
    app_rate_20: 0.1,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seth',
    round: 2.88,
    char_1: 'jane-doe',
    app_rate_1: 81.62,
    avg_round_1: 2.89,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 20.22,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 19.0,
    avg_round_3: 2.94,
    app_flat_3: 0,
    char_4: 'Rina',
    app_rate_4: 17.46,
    avg_round_4: 2.88,
    app_flat_4: 0,
    char_5: 'Burnice',
    app_rate_5: 14.89,
    avg_round_5: 2.88,
    app_flat_5: 0,
    char_6: 'Yanagi',
    app_rate_6: 12.93,
    avg_round_6: 2.92,
    app_flat_6: 0,
    char_7: 'grace-howard',
    app_rate_7: 8.39,
    avg_round_7: 2.83,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 6.5,
    avg_round_8: 2.79,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 4.17,
    avg_round_9: 2.87,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 3.43,
    avg_round_10: 2.63,
    app_flat_10: 0,
    char_11: 'Piper',
    app_rate_11: 1.84,
    avg_round_11: 2.77,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 1.53,
    avg_round_12: 2.96,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 1.29,
    avg_round_13: 2.63,
    app_flat_13: 0,
    char_14: 'Anton',
    app_rate_14: 0.86,
    avg_round_14: 2.14,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 0.61,
    avg_round_15: 2.78,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.49,
    avg_round_16: 2.33,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.49,
    avg_round_17: 2.86,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 0.25,
    avg_round_18: 2.67,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.18,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'billy-kid',
    app_rate_20: 0.06,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.06,
    avg_round_21: 2,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lucy',
    round: 2.88,
    char_1: 'Burnice',
    app_rate_1: 51.1,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'jane-doe',
    app_rate_2: 37.4,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'Piper',
    app_rate_3: 26.47,
    avg_round_3: 2.88,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 23.94,
    avg_round_4: 2.82,
    app_flat_4: 0,
    char_5: 'soldier-11',
    app_rate_5: 20.23,
    avg_round_5: 2.81,
    app_flat_5: 0,
    char_6: 'Seth',
    app_rate_6: 12.64,
    avg_round_6: 2.94,
    app_flat_6: 0,
    char_7: 'Yanagi',
    app_rate_7: 6.77,
    avg_round_7: 2.92,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 5.63,
    avg_round_8: 2.84,
    app_flat_8: 0,
    char_9: 'Nekomata',
    app_rate_9: 3.22,
    avg_round_9: 2.83,
    app_flat_9: 0,
    char_10: 'Qingyi',
    app_rate_10: 2.49,
    avg_round_10: 2.92,
    app_flat_10: 0,
    char_11: 'Ben',
    app_rate_11: 1.96,
    avg_round_11: 2.64,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 1.67,
    avg_round_12: 2.92,
    app_flat_12: 0,
    char_13: 'Rina',
    app_rate_13: 1.06,
    avg_round_13: 2.95,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 0.94,
    avg_round_14: 2.71,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 0.73,
    avg_round_15: 2.53,
    app_flat_15: 0,
    char_16: 'anby-demara',
    app_rate_16: 0.73,
    avg_round_16: 2.65,
    app_flat_16: 0,
    char_17: 'Ellen',
    app_rate_17: 0.65,
    avg_round_17: 2.85,
    app_flat_17: 0,
    char_18: 'nicole-demara',
    app_rate_18: 0.61,
    avg_round_18: 2.8,
    app_flat_18: 0,
    char_19: 'zhu-yuan',
    app_rate_19: 0.57,
    avg_round_19: 2.75,
    app_flat_19: 0,
    char_20: 'Soukaku',
    app_rate_20: 0.49,
    avg_round_20: 2.8,
    app_flat_20: 0,
    char_21: 'Lycaon',
    app_rate_21: 0.45,
    avg_round_21: 2.64,
    app_flat_21: 0,
    char_22: 'Anton',
    app_rate_22: 0.12,
    avg_round_22: 3,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Caesar',
    round: 2.88,
    char_1: 'Burnice',
    app_rate_1: 40.05,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'jane-doe',
    app_rate_2: 38.37,
    avg_round_2: 2.91,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 22.87,
    avg_round_3: 2.82,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 15.27,
    avg_round_4: 2.89,
    app_flat_4: 0,
    char_5: 'Seth',
    app_rate_5: 12.86,
    avg_round_5: 2.93,
    app_flat_5: 0,
    char_6: 'Ellen',
    app_rate_6: 12.12,
    avg_round_6: 2.92,
    app_flat_6: 0,
    char_7: 'soldier-11',
    app_rate_7: 12.04,
    avg_round_7: 2.81,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 8.34,
    avg_round_8: 2.92,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 6.9,
    avg_round_9: 2.96,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 5.38,
    avg_round_10: 2.81,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 4.64,
    avg_round_11: 2.89,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 4.44,
    avg_round_12: 2.79,
    app_flat_12: 0,
    char_13: 'Nekomata',
    app_rate_13: 3.19,
    avg_round_13: 2.82,
    app_flat_13: 0,
    char_14: 'Rina',
    app_rate_14: 2.96,
    avg_round_14: 2.91,
    app_flat_14: 0,
    char_15: 'Qingyi',
    app_rate_15: 2.57,
    avg_round_15: 2.64,
    app_flat_15: 0,
    char_16: 'Corin',
    app_rate_16: 1.99,
    avg_round_16: 2.8,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 1.6,
    avg_round_17: 2.72,
    app_flat_17: 0,
    char_18: 'grace-howard',
    app_rate_18: 1.48,
    avg_round_18: 2.82,
    app_flat_18: 0,
    char_19: 'Koleda',
    app_rate_19: 1.21,
    avg_round_19: 2.52,
    app_flat_19: 0,
    char_20: 'Anton',
    app_rate_20: 0.7,
    avg_round_20: 2.69,
    app_flat_20: 0,
    char_21: 'anby-demara',
    app_rate_21: 0.47,
    avg_round_21: 2.78,
    app_flat_21: 0,
    char_22: 'Ben',
    app_rate_22: 0.16,
    avg_round_22: 2.5,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingyi',
    round: 2.89,
    char_1: 'nicole-demara',
    app_rate_1: 66.03,
    avg_round_1: 2.94,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 65.99,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 13.37,
    avg_round_3: 2.79,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 11.76,
    avg_round_4: 2.8,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 8.28,
    avg_round_5: 2.81,
    app_flat_5: 0,
    char_6: 'jane-doe',
    app_rate_6: 5.04,
    avg_round_6: 2.8,
    app_flat_6: 0,
    char_7: 'Yanagi',
    app_rate_7: 4.92,
    avg_round_7: 2.8,
    app_flat_7: 0,
    char_8: 'Anton',
    app_rate_8: 4.76,
    avg_round_8: 2.84,
    app_flat_8: 0,
    char_9: 'Seth',
    app_rate_9: 4.24,
    avg_round_9: 2.79,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 2.68,
    avg_round_10: 2.77,
    app_flat_10: 0,
    char_11: 'Caesar',
    app_rate_11: 2.64,
    avg_round_11: 2.64,
    app_flat_11: 0,
    char_12: 'Lucy',
    app_rate_12: 2.44,
    avg_round_12: 2.92,
    app_flat_12: 0,
    char_13: 'soldier-11',
    app_rate_13: 1.76,
    avg_round_13: 2.94,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 1.64,
    avg_round_14: 2.74,
    app_flat_14: 0,
    char_15: 'Burnice',
    app_rate_15: 1.2,
    avg_round_15: 2.84,
    app_flat_15: 0,
    char_16: 'Nekomata',
    app_rate_16: 1.16,
    avg_round_16: 2.78,
    app_flat_16: 0,
    char_17: 'Piper',
    app_rate_17: 0.48,
    avg_round_17: 2.78,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.44,
    avg_round_18: 2.62,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 0.32,
    avg_round_19: 2.38,
    app_flat_19: 0,
    char_20: 'Lycaon',
    app_rate_20: 0.28,
    avg_round_20: 2.8,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.08,
    avg_round_21: 1,
    app_flat_21: 0,
    char_22: 'Koleda',
    app_rate_22: 0.04,
    avg_round_22: 1,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    round: 2.9,
    char_1: 'nicole-demara',
    app_rate_1: 94.85,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 76.48,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 13.91,
    avg_round_3: 2.87,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 5.29,
    avg_round_4: 2.79,
    app_flat_4: 0,
    char_5: 'Koleda',
    app_rate_5: 2.27,
    avg_round_5: 2.82,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 1.44,
    avg_round_6: 2.73,
    app_flat_6: 0,
    char_7: 'Lycaon',
    app_rate_7: 1.02,
    avg_round_7: 2.79,
    app_flat_7: 0,
    char_8: 'Seth',
    app_rate_8: 0.97,
    avg_round_8: 2.63,
    app_flat_8: 0,
    char_9: 'Yanagi',
    app_rate_9: 0.74,
    avg_round_9: 2.55,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 0.7,
    avg_round_10: 2.58,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 0.65,
    avg_round_11: 2.75,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 0.65,
    avg_round_12: 2.64,
    app_flat_12: 0,
    char_13: 'Ellen',
    app_rate_13: 0.37,
    avg_round_13: 2.4,
    app_flat_13: 0,
    char_14: 'Burnice',
    app_rate_14: 0.32,
    avg_round_14: 2.83,
    app_flat_14: 0,
    char_15: 'Soukaku',
    app_rate_15: 0.14,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.14,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    round: 2.9,
    char_1: 'zhu-yuan',
    app_rate_1: 85.96,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 69.36,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 15.05,
    avg_round_3: 2.84,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 5.8,
    avg_round_4: 2.81,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 3.32,
    avg_round_5: 2.89,
    app_flat_5: 0,
    char_6: 'Seth',
    app_rate_6: 2.86,
    avg_round_6: 2.87,
    app_flat_6: 0,
    char_7: 'Nekomata',
    app_rate_7: 2.14,
    avg_round_7: 2.84,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 2.06,
    avg_round_8: 2.8,
    app_flat_8: 0,
    char_9: 'Yanagi',
    app_rate_9: 1.85,
    avg_round_9: 2.87,
    app_flat_9: 0,
    char_10: 'Lycaon',
    app_rate_10: 1.6,
    avg_round_10: 2.82,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 1.39,
    avg_round_11: 2.73,
    app_flat_11: 0,
    char_12: 'billy-kid',
    app_rate_12: 1.35,
    avg_round_12: 2.63,
    app_flat_12: 0,
    char_13: 'Anton',
    app_rate_13: 1.3,
    avg_round_13: 2.83,
    app_flat_13: 0,
    char_14: 'Ellen',
    app_rate_14: 1.18,
    avg_round_14: 2.67,
    app_flat_14: 0,
    char_15: 'Soukaku',
    app_rate_15: 1.01,
    avg_round_15: 2.67,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 0.84,
    avg_round_16: 2.85,
    app_flat_16: 0,
    char_17: 'Burnice',
    app_rate_17: 0.71,
    avg_round_17: 2.94,
    app_flat_17: 0,
    char_18: 'Lucy',
    app_rate_18: 0.63,
    avg_round_18: 2.8,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.59,
    avg_round_19: 2.45,
    app_flat_19: 0,
    char_20: 'Rina',
    app_rate_20: 0.55,
    avg_round_20: 2.9,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.29,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: 'Ben',
    app_rate_22: 0.17,
    avg_round_22: 2.25,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    round: 2.9,
    char_1: 'Burnice',
    app_rate_1: 49.24,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 48.4,
    avg_round_2: 2.89,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 35.79,
    avg_round_3: 2.91,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 33.32,
    avg_round_4: 2.94,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 7.81,
    avg_round_5: 2.89,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 4.58,
    avg_round_6: 2.8,
    app_flat_6: 0,
    char_7: 'grace-howard',
    app_rate_7: 4.29,
    avg_round_7: 2.79,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 2.94,
    avg_round_8: 2.79,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.87,
    avg_round_9: 2.89,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 1.85,
    avg_round_10: 2.74,
    app_flat_10: 0,
    char_11: 'Koleda',
    app_rate_11: 1.71,
    avg_round_11: 2.81,
    app_flat_11: 0,
    char_12: 'Nekomata',
    app_rate_12: 0.62,
    avg_round_12: 2.77,
    app_flat_12: 0,
    char_13: 'Soukaku',
    app_rate_13: 0.58,
    avg_round_13: 2.64,
    app_flat_13: 0,
    char_14: 'Yanagi',
    app_rate_14: 0.55,
    avg_round_14: 2.5,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 0.51,
    avg_round_15: 2.64,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 0.47,
    avg_round_16: 2.27,
    app_flat_16: 0,
    char_17: 'Lycaon',
    app_rate_17: 0.33,
    avg_round_17: 2.62,
    app_flat_17: 0,
    char_18: 'Ben',
    app_rate_18: 0.22,
    avg_round_18: 2.83,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.18,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Ellen',
    app_rate_20: 0.11,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.07,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanagi',
    round: 2.9,
    char_1: 'Rina',
    app_rate_1: 39.77,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 37.01,
    avg_round_2: 2.96,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 24.6,
    avg_round_3: 2.92,
    app_flat_3: 0,
    char_4: 'Seth',
    app_rate_4: 24.25,
    avg_round_4: 2.92,
    app_flat_4: 0,
    char_5: 'Lucy',
    app_rate_5: 19.08,
    avg_round_5: 2.92,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 14.14,
    avg_round_6: 2.8,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 8.97,
    avg_round_7: 2.83,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 5.98,
    avg_round_8: 2.89,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 5.52,
    avg_round_9: 2.88,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 5.06,
    avg_round_10: 2.87,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 4.6,
    avg_round_11: 2.89,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 1.84,
    avg_round_12: 2.55,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 1.72,
    avg_round_13: 2.5,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 1.61,
    avg_round_14: 2.9,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 0.8,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.46,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Anton',
    app_rate_17: 0.34,
    avg_round_17: 2.5,
    app_flat_17: 0,
    char_18: 'Ben',
    app_rate_18: 0.23,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.11,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Burnice',
    round: 2.92,
    char_1: 'jane-doe',
    app_rate_1: 50.5,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 46.7,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 38.32,
    avg_round_3: 2.92,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 21.92,
    avg_round_4: 2.92,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 12.0,
    avg_round_5: 2.96,
    app_flat_5: 0,
    char_6: 'Seth',
    app_rate_6: 9.06,
    avg_round_6: 2.88,
    app_flat_6: 0,
    char_7: 'soldier-11',
    app_rate_7: 5.11,
    avg_round_7: 2.88,
    app_flat_7: 0,
    char_8: 'Rina',
    app_rate_8: 4.73,
    avg_round_8: 2.93,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 4.47,
    avg_round_9: 2.89,
    app_flat_9: 0,
    char_10: 'Koleda',
    app_rate_10: 1.53,
    avg_round_10: 2.8,
    app_flat_10: 0,
    char_11: 'Qingyi',
    app_rate_11: 1.12,
    avg_round_11: 2.84,
    app_flat_11: 0,
    char_12: 'Nekomata',
    app_rate_12: 0.67,
    avg_round_12: 2.75,
    app_flat_12: 0,
    char_13: 'anby-demara',
    app_rate_13: 0.63,
    avg_round_13: 2.86,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 0.63,
    avg_round_14: 2.94,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 0.6,
    avg_round_15: 2.8,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.52,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Soukaku',
    app_rate_17: 0.34,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.26,
    avg_round_18: 2.83,
    app_flat_18: 0,
    char_19: 'Anton',
    app_rate_19: 0.19,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'billy-kid',
    app_rate_20: 0.19,
    avg_round_20: 2.75,
    app_flat_20: 0,
    char_21: 'Corin',
    app_rate_21: 0.15,
    avg_round_21: 2.75,
    app_flat_21: 0,
    char_22: 'Lycaon',
    app_rate_22: 0.15,
    avg_round_22: 2.67,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
