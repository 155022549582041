export const ZZZBuilds = [
  {
    char: 'Burnice',
    app_rate: 55.27,
    avg_round: 2.92,
    std_dev_round: 0.3,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 21.45,
    diff_rounds: -0.04,
    weapon_1: 'Flamemaker Shaker',
    weapon_1_app: 40.51,
    weapon_1_round: 2.94,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 22.96,
    weapon_2_round: 2.9,
    weapon_3: 'Electro-Lip Gloss',
    weapon_3_app: 15.06,
    weapon_3_round: 2.96,
    weapon_4: 'Roaring Ride',
    weapon_4_app: 8.27,
    weapon_4_round: 2.88,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 6.97,
    weapon_5_round: 2.9,
    weapon_6: 'Rainforest Gourmet',
    weapon_6_app: 5.74,
    weapon_6_round: 2.89,
    weapon_7: 'Sharpened Stinger',
    weapon_7_app: 0.07,
    weapon_7_round: 3.0,
    weapon_8: '[Magnetic Storm] Charlie',
    weapon_8_app: 0.04,
    weapon_8_round: 3.0,
    weapon_9: '[Magnetic Storm] Bravo',
    weapon_9_app: 0.04,
    weapon_9_round: 1.0,
    weapon_10: '[Magnetic Storm] Alpha',
    weapon_10_app: 0.04,
    weapon_10_round: 3.0,
    artifact_1: 'Chaos Jazz, Freedom Blues',
    artifact_1_1: 'Chaos Jazz',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 50.24,
    artifact_1_round: 2.91,
    artifact_2: 'Chaos Jazz, Inferno Metal',
    artifact_2_1: 'Chaos Jazz',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 21.62,
    artifact_2_round: 2.93,
    artifact_3: 'Chaos Jazz, Swing Jazz',
    artifact_3_1: 'Chaos Jazz',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 19.83,
    artifact_3_round: 2.93,
    artifact_4: 'Chaos Jazz, Hormone Punk',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 2.57,
    artifact_4_round: 2.96,
    artifact_5: 'Chaos Jazz, Puffer Electro',
    artifact_5_1: 'Chaos Jazz',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 1.38,
    artifact_5_round: 2.95,
    artifact_6: 'Chaos Jazz, Flex',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.01,
    artifact_6_round: 3.0,
    artifact_7: 'Inferno Metal, Woodpecker Electro',
    artifact_7_1: 'Inferno Metal',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 0.41,
    artifact_7_round: 2.8,
    artifact_8: 'Freedom Blues, Chaos Jazz',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Chaos Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.41,
    artifact_8_round: 2.73,
    artifact_9: 'Chaos Jazz, Woodpecker Electro',
    artifact_9_1: 'Chaos Jazz',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 0.34,
    artifact_9_round: 3.0,
    artifact_10: 'Inferno Metal, Chaos Jazz',
    artifact_10_1: 'Inferno Metal',
    artifact_10_2: 'Chaos Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.34,
    artifact_10_round: 2.5,
    app_0: 89.3,
    round_0: 2.92,
    app_1: 6.71,
    round_1: 2.98,
    app_2: 2.39,
    round_2: 2.98,
    app_3: 0.41,
    round_3: 3.0,
    app_4: 0.07,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.12,
    round_6: 3.0,
    cons_avg: 0.2,
    sample: 2683,
    sample_app_flat: 2165
  },
  {
    char: 'nicole-demara',
    app_rate: 49.01,
    avg_round: 2.9,
    std_dev_round: 0.34,
    role: 'Support',
    rarity: 'A',
    diff: -7.26,
    diff_rounds: -0.02,
    weapon_1: 'The Vault',
    weapon_1_app: 90.04,
    weapon_1_round: 2.91,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 5.21,
    weapon_2_round: 2.87,
    weapon_3: 'Slice of Time',
    weapon_3_app: 1.64,
    weapon_3_round: 2.76,
    weapon_4: 'Kaboom the Cannon',
    weapon_4_app: 1.51,
    weapon_4_round: 2.76,
    weapon_5: '[Reverb] Mark II',
    weapon_5_app: 0.46,
    weapon_5_round: 2.7,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 0.42,
    weapon_6_round: 3.0,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 0.29,
    weapon_7_round: 3.0,
    weapon_8: 'Bashful Demon',
    weapon_8_app: 0.04,
    weapon_8_round: 1.0,
    weapon_9: '[Reverb] Mark I',
    weapon_9_app: 0.04,
    weapon_9_round: 3.0,
    weapon_10: 'Gilded Blossom',
    weapon_10_app: 0.04,
    weapon_10_round: 2.0,
    artifact_1: 'Swing Jazz, Chaotic Metal',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Chaotic Metal',
    artifact_1_3: '',
    artifact_1_app: 42.75,
    artifact_1_round: 2.91,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 28.71,
    artifact_2_round: 2.91,
    artifact_3: 'Swing Jazz, Flex',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 4.58,
    artifact_3_round: 2.92,
    artifact_4: 'Chaotic Metal, Swing Jazz',
    artifact_4_1: 'Chaotic Metal',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 3.49,
    artifact_4_round: 2.78,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 3.49,
    artifact_5_round: 2.84,
    artifact_6: 'Swing Jazz, Woodpecker Electro',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 3.45,
    artifact_6_round: 2.94,
    artifact_7: 'Swing Jazz, Hormone Punk',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 2.56,
    artifact_7_round: 2.96,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.68,
    artifact_8_round: 3.0,
    artifact_9: 'Swing Jazz, Chaos Jazz',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Chaos Jazz',
    artifact_9_3: '',
    artifact_9_app: 1.3,
    artifact_9_round: 3.0,
    artifact_10: 'Freedom Blues, Chaotic Metal',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Chaotic Metal',
    artifact_10_3: '',
    artifact_10_app: 0.8,
    artifact_10_round: 2.83,
    app_0: 0.29,
    round_0: 2.29,
    app_1: 0.55,
    round_1: 2.64,
    app_2: 1.3,
    round_2: 2.69,
    app_3: 1.85,
    round_3: 2.84,
    app_4: 2.19,
    round_4: 2.77,
    app_5: 3.32,
    round_5: 2.78,
    app_6: 90.5,
    round_6: 2.92,
    cons_avg: 5.77,
    sample: 2379,
    sample_app_flat: 1846
  },
  {
    char: 'zhu-yuan',
    app_rate: 44.42,
    avg_round: 2.9,
    std_dev_round: 0.32,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -6.62,
    diff_rounds: -0.03,
    weapon_1: 'Riot Suppressor Mark VI',
    weapon_1_app: 44.71,
    weapon_1_round: 2.95,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 31.45,
    weapon_2_round: 2.89,
    weapon_3: 'The Brimstone',
    weapon_3_app: 12.24,
    weapon_3_round: 2.91,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 5.19,
    weapon_4_round: 2.9,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 3.8,
    weapon_5_round: 2.62,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 1.48,
    weapon_6_round: 2.97,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.6,
    weapon_7_round: 2.77,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.23,
    weapon_8_round: 2.6,
    weapon_9: 'Housekeeper',
    weapon_9_app: 0.05,
    weapon_9_round: 3.0,
    weapon_10: 'Kaboom the Cannon',
    weapon_10_app: 0.05,
    weapon_10_round: 3.0,
    artifact_1: 'Chaotic Metal, Woodpecker Electro',
    artifact_1_1: 'Chaotic Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 80.84,
    artifact_1_round: 2.9,
    artifact_2: 'Chaotic Metal, Hormone Punk',
    artifact_2_1: 'Chaotic Metal',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 5.43,
    artifact_2_round: 2.96,
    artifact_3: 'Chaotic Metal, Swing Jazz',
    artifact_3_1: 'Chaotic Metal',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 4.22,
    artifact_3_round: 2.9,
    artifact_4: 'Woodpecker Electro, Chaotic Metal',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Chaotic Metal',
    artifact_4_3: '',
    artifact_4_app: 3.15,
    artifact_4_round: 2.84,
    artifact_5: 'Chaotic Metal, Flex',
    artifact_5_1: 'Chaotic Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 1.67,
    artifact_5_round: 2.93,
    artifact_6: 'Chaotic Metal, Puffer Electro',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 1.58,
    artifact_6_round: 2.95,
    artifact_7: 'Hormone Punk, Woodpecker Electro',
    artifact_7_1: 'Hormone Punk',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 0.56,
    artifact_7_round: 3.0,
    artifact_8: 'Hormone Punk, Chaotic Metal',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Chaotic Metal',
    artifact_8_3: '',
    artifact_8_app: 0.51,
    artifact_8_round: 2.86,
    artifact_9: 'Woodpecker Electro, Hormone Punk',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.51,
    artifact_9_round: 3.0,
    artifact_10: 'Chaotic Metal, Freedom Blues',
    artifact_10_1: 'Chaotic Metal',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.37,
    artifact_10_round: 2.4,
    app_0: 80.01,
    round_0: 2.9,
    app_1: 14.7,
    round_1: 2.96,
    app_2: 2.83,
    round_2: 2.98,
    app_3: 0.42,
    round_3: 3.0,
    app_4: 0.37,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.67,
    round_6: 3.0,
    cons_avg: 0.33,
    sample: 2156,
    sample_app_flat: 1641
  },
  {
    char: 'jane-doe',
    app_rate: 56.7,
    avg_round: 2.9,
    std_dev_round: 0.34,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -2.1,
    diff_rounds: -0.04,
    weapon_1: 'Sharpened Stinger',
    weapon_1_app: 55.38,
    weapon_1_round: 2.94,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 14.9,
    weapon_2_round: 2.84,
    weapon_3: 'Weeping Gemini',
    weapon_3_app: 14.24,
    weapon_3_round: 2.88,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 10.65,
    weapon_4_round: 2.91,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 3.42,
    weapon_5_round: 2.75,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.62,
    weapon_6_round: 2.88,
    weapon_7: '[Magnetic Storm] Bravo',
    weapon_7_app: 0.07,
    weapon_7_round: 3.0,
    weapon_8: 'Flamemaker Shaker',
    weapon_8_app: 0.07,
    weapon_8_round: 3.0,
    weapon_9: '[Magnetic Storm] Alpha',
    weapon_9_app: 0.07,
    weapon_9_round: 3.0,
    weapon_10: 'Slice of Time',
    weapon_10_app: 0.04,
    weapon_10_round: 3.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 87.21,
    artifact_1_round: 2.9,
    artifact_2: 'Fanged Metal, Chaos Jazz',
    artifact_2_1: 'Fanged Metal',
    artifact_2_2: 'Chaos Jazz',
    artifact_2_3: '',
    artifact_2_app: 4.83,
    artifact_2_round: 2.99,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 2.69,
    artifact_3_round: 2.92,
    artifact_4: 'Freedom Blues, Fanged Metal',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 1.85,
    artifact_4_round: 2.77,
    artifact_5: 'Fanged Metal, Puffer Electro',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 0.69,
    artifact_5_round: 2.82,
    artifact_6: 'Fanged Metal, Flex',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 0.55,
    artifact_6_round: 3.0,
    artifact_7: 'Freedom Blues, Chaos Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Chaos Jazz',
    artifact_7_3: '',
    artifact_7_app: 0.36,
    artifact_7_round: 2.75,
    artifact_8: 'Fanged Metal, Swing Jazz',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.22,
    artifact_8_round: 2.8,
    artifact_9: 'AP +30, AP +30, Fanged Metal',
    artifact_9_1: 'AP +30',
    artifact_9_2: 'AP +30',
    artifact_9_3: 'Fanged Metal',
    artifact_9_app: 0.18,
    artifact_9_round: 2.67,
    artifact_10: 'Fanged Metal, AP +30, Hormone Punk',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'AP +30',
    artifact_10_3: 'Hormone Punk',
    artifact_10_app: 0.15,
    artifact_10_round: 3.0,
    app_0: 82.12,
    round_0: 2.9,
    app_1: 8.25,
    round_1: 2.92,
    app_2: 7.38,
    round_2: 3.0,
    app_3: 0.44,
    round_3: 2.92,
    app_4: 0.11,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.71,
    round_6: 3.0,
    cons_avg: 0.35,
    sample: 2752,
    sample_app_flat: 2139
  },
  {
    char: 'Yanagi',
    app_rate: 17.92,
    avg_round: 2.9,
    std_dev_round: 0.32,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 0.0,
    diff_rounds: 1,
    weapon_1: 'Timeweaver',
    weapon_1_app: 49.54,
    weapon_1_round: 2.94,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 24.25,
    weapon_2_round: 2.87,
    weapon_3: 'Fusion Compiler',
    weapon_3_app: 15.06,
    weapon_3_round: 2.92,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 6.32,
    weapon_4_round: 2.86,
    weapon_5: 'Rainforest Gourmet',
    weapon_5_app: 3.22,
    weapon_5_round: 2.74,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.92,
    weapon_6_round: 3.0,
    weapon_7: '[Magnetic Storm] Bravo',
    weapon_7_app: 0.23,
    weapon_7_round: 2.0,
    weapon_8: 'Deep Sea Visitor',
    weapon_8_app: 0.11,
    weapon_8_round: 3.0,
    weapon_9: 'Flamemaker Shaker',
    weapon_9_app: 0.11,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Chaos Jazz, Freedom Blues',
    artifact_1_1: 'Chaos Jazz',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 60.8,
    artifact_1_round: 2.92,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 11.61,
    artifact_2_round: 2.87,
    artifact_3: 'Thunder Metal, Chaos Jazz',
    artifact_3_1: 'Thunder Metal',
    artifact_3_2: 'Chaos Jazz',
    artifact_3_3: '',
    artifact_3_app: 8.28,
    artifact_3_round: 2.95,
    artifact_4: 'Chaos Jazz, Thunder Metal',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Thunder Metal',
    artifact_4_3: '',
    artifact_4_app: 5.63,
    artifact_4_round: 2.9,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 2.64,
    artifact_5_round: 2.82,
    artifact_6: 'Chaos Jazz, Puffer Electro',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 2.3,
    artifact_6_round: 2.93,
    artifact_7: 'AP +30, AP +30, Puffer Electro',
    artifact_7_1: 'AP +30',
    artifact_7_2: 'AP +30',
    artifact_7_3: 'Puffer Electro',
    artifact_7_app: 1.49,
    artifact_7_round: 3.0,
    artifact_8: 'AP +30, AP +30, Thunder Metal',
    artifact_8_1: 'AP +30',
    artifact_8_2: 'AP +30',
    artifact_8_3: 'Thunder Metal',
    artifact_8_app: 1.26,
    artifact_8_round: 2.67,
    artifact_9: 'Freedom Blues, Thunder Metal',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: '',
    artifact_9_app: 1.03,
    artifact_9_round: 2.5,
    artifact_10: 'Chaos Jazz, Hormone Punk',
    artifact_10_1: 'Chaos Jazz',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 0.8,
    artifact_10_round: 3.0,
    app_0: 87.01,
    round_0: 2.9,
    app_1: 5.63,
    round_1: 3.0,
    app_2: 5.17,
    round_2: 3.0,
    app_3: 0.46,
    round_3: 3.0,
    app_4: 0.11,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.61,
    round_6: 3.0,
    cons_avg: 0.27,
    sample: 870,
    sample_app_flat: 715
  },
  {
    char: 'Qingyi',
    app_rate: 51.48,
    avg_round: 2.89,
    std_dev_round: 0.35,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 0.59,
    diff_rounds: -0.03,
    weapon_1: 'Ice-Jade Teapot',
    weapon_1_app: 34.77,
    weapon_1_round: 2.93,
    weapon_2: 'Steam Oven',
    weapon_2_app: 22.49,
    weapon_2_round: 2.88,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 14.05,
    weapon_3_round: 2.85,
    weapon_4: 'The Restrained',
    weapon_4_app: 13.21,
    weapon_4_round: 2.92,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 8.28,
    weapon_5_round: 2.85,
    weapon_6: 'Precious Fossilized Core',
    weapon_6_app: 5.52,
    weapon_6_round: 2.8,
    weapon_7: 'Six Shooter',
    weapon_7_app: 1.28,
    weapon_7_round: 2.93,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.28,
    weapon_8_round: 2.71,
    weapon_9: 'Spring Embrace',
    weapon_9_app: 0.04,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 39.78,
    artifact_1_round: 2.9,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 38.46,
    artifact_2_round: 2.88,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 12.81,
    artifact_3_round: 2.92,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 2.2,
    artifact_4_round: 2.86,
    artifact_5: 'Shockstar Disco, Hormone Punk',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 1.76,
    artifact_5_round: 2.84,
    artifact_6: 'Shockstar Disco, Freedom Blues',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 1.16,
    artifact_6_round: 3.0,
    artifact_7: 'Shockstar Disco, Puffer Electro',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.12,
    artifact_7_round: 2.9,
    artifact_8: 'Thunder Metal, Shockstar Disco',
    artifact_8_1: 'Thunder Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.52,
    artifact_8_round: 2.54,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 0.2,
    artifact_9_round: 2.5,
    artifact_10: 'Thunder Metal, Woodpecker Electro',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 0.2,
    artifact_10_round: 2.25,
    app_0: 86.39,
    round_0: 2.89,
    app_1: 9.24,
    round_1: 2.97,
    app_2: 2.44,
    round_2: 2.98,
    app_3: 0.64,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.28,
    round_6: 3.0,
    cons_avg: 0.24,
    sample: 2499,
    sample_app_flat: 1842
  },
  {
    char: 'Lucy',
    app_rate: 50.52,
    avg_round: 2.88,
    std_dev_round: 0.37,
    role: 'Support',
    rarity: 'A',
    diff: 6.34,
    diff_rounds: -0.03,
    weapon_1: 'Kaboom the Cannon',
    weapon_1_app: 87.44,
    weapon_1_round: 2.9,
    weapon_2: 'Slice of Time',
    weapon_2_app: 4.08,
    weapon_2_round: 2.68,
    weapon_3: '[Reverb] Mark II',
    weapon_3_app: 2.77,
    weapon_3_round: 2.75,
    weapon_4: 'Weeping Cradle',
    weapon_4_app: 2.28,
    weapon_4_round: 2.9,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 1.1,
    weapon_5_round: 2.84,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 0.73,
    weapon_6_round: 2.72,
    weapon_7: 'Bashful Demon',
    weapon_7_app: 0.57,
    weapon_7_round: 2.71,
    weapon_8: 'The Vault',
    weapon_8_app: 0.29,
    weapon_8_round: 2.86,
    weapon_9: '[Reverb] Mark I',
    weapon_9_app: 0.2,
    weapon_9_round: 3.0,
    weapon_10: 'Gilded Blossom',
    weapon_10_app: 0.04,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 60.2,
    artifact_1_round: 2.88,
    artifact_2: 'Swing Jazz, Woodpecker Electro',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 7.42,
    artifact_2_round: 2.96,
    artifact_3: 'Swing Jazz, Freedom Blues',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 4.4,
    artifact_3_round: 2.93,
    artifact_4: 'Hormone Punk, Swing Jazz',
    artifact_4_1: 'Hormone Punk',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 3.14,
    artifact_4_round: 2.72,
    artifact_5: 'Swing Jazz, Flex',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 3.1,
    artifact_5_round: 2.85,
    artifact_6: 'Swing Jazz, Inferno Metal',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Inferno Metal',
    artifact_6_3: '',
    artifact_6_app: 2.69,
    artifact_6_round: 2.81,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 1.75,
    artifact_7_round: 2.87,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.43,
    artifact_8_round: 3.0,
    artifact_9: 'Freedom Blues, Hormone Punk',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 1.18,
    artifact_9_round: 2.9,
    artifact_10: 'Hormone Punk, Swing Jazz, Flex',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 1.18,
    artifact_10_round: 2.83,
    app_0: 0.57,
    round_0: 2.46,
    app_1: 1.79,
    round_1: 2.63,
    app_2: 5.18,
    round_2: 2.81,
    app_3: 7.14,
    round_3: 2.85,
    app_4: 8.08,
    round_4: 2.81,
    app_5: 7.95,
    round_5: 2.87,
    app_6: 69.29,
    round_6: 2.92,
    cons_avg: 5.21,
    sample: 2452,
    sample_app_flat: 2104
  },
  {
    char: 'Seth',
    app_rate: 33.62,
    avg_round: 2.88,
    std_dev_round: 0.37,
    role: 'Support',
    rarity: 'A',
    diff: -2.43,
    diff_rounds: -0.06,
    weapon_1: 'Peacekeeper - Specialized',
    weapon_1_app: 79.78,
    weapon_1_round: 2.89,
    weapon_2: 'Bunny Band',
    weapon_2_app: 6.19,
    weapon_2_round: 2.73,
    weapon_3: 'Spring Embrace',
    weapon_3_app: 4.47,
    weapon_3_round: 2.91,
    weapon_4: 'Original Transmorpher',
    weapon_4_app: 2.76,
    weapon_4_round: 2.85,
    weapon_5: 'Big Cylinder',
    weapon_5_app: 1.35,
    weapon_5_round: 2.78,
    weapon_6: 'Starlight Engine',
    weapon_6_app: 1.1,
    weapon_6_round: 2.71,
    weapon_7: 'Tusks of Fury',
    weapon_7_app: 0.55,
    weapon_7_round: 3.0,
    weapon_8: 'Street Superstar',
    weapon_8_app: 0.43,
    weapon_8_round: 3.0,
    weapon_9: 'Gilded Blossom',
    weapon_9_app: 0.37,
    weapon_9_round: 2.83,
    weapon_10: 'Demara Battery Mark II',
    weapon_10_app: 0.31,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 33.39,
    artifact_1_round: 2.83,
    artifact_2: 'Proto Punk, Hormone Punk',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 10.78,
    artifact_2_round: 2.93,
    artifact_3: 'Swing Jazz, Freedom Blues',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 8.88,
    artifact_3_round: 2.85,
    artifact_4: 'Proto Punk, Swing Jazz',
    artifact_4_1: 'Proto Punk',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 5.7,
    artifact_4_round: 2.99,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 4.29,
    artifact_5_round: 2.89,
    artifact_6: 'Proto Punk, Shockstar Disco',
    artifact_6_1: 'Proto Punk',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 3.43,
    artifact_6_round: 2.95,
    artifact_7: 'Freedom Blues, Hormone Punk',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 3.12,
    artifact_7_round: 2.97,
    artifact_8: 'Hormone Punk, Swing Jazz',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 2.7,
    artifact_8_round: 2.87,
    artifact_9: 'Proto Punk, Freedom Blues',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Freedom Blues',
    artifact_9_3: '',
    artifact_9_app: 2.08,
    artifact_9_round: 2.93,
    artifact_10: 'Swing Jazz, Shockstar Disco',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 1.65,
    artifact_10_round: 2.95,
    app_0: 5.88,
    round_0: 2.78,
    app_1: 10.6,
    round_1: 2.8,
    app_2: 11.52,
    round_2: 2.89,
    app_3: 13.66,
    round_3: 2.89,
    app_4: 12.87,
    round_4: 2.88,
    app_5: 9.38,
    round_5: 2.93,
    app_6: 36.09,
    round_6: 2.9,
    cons_avg: 3.9,
    sample: 1632,
    sample_app_flat: 1315
  },
  {
    char: 'Caesar',
    app_rate: 52.88,
    avg_round: 2.88,
    std_dev_round: 0.37,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 0.01,
    diff_rounds: -0.04,
    weapon_1: 'Tusks of Fury',
    weapon_1_app: 49.63,
    weapon_1_round: 2.91,
    weapon_2: 'Original Transmorpher',
    weapon_2_app: 20.1,
    weapon_2_round: 2.78,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 9.12,
    weapon_3_round: 2.93,
    weapon_4: 'Precious Fossilized Core',
    weapon_4_app: 6.39,
    weapon_4_round: 2.95,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 4.32,
    weapon_5_round: 2.84,
    weapon_6: 'The Restrained',
    weapon_6_app: 3.7,
    weapon_6_round: 2.94,
    weapon_7: 'Spring Embrace',
    weapon_7_app: 2.3,
    weapon_7_round: 2.94,
    weapon_8: 'Peacekeeper - Specialized',
    weapon_8_app: 1.48,
    weapon_8_round: 2.96,
    weapon_9: 'Bunny Band',
    weapon_9_app: 0.86,
    weapon_9_round: 2.64,
    weapon_10: 'Big Cylinder',
    weapon_10_app: 0.7,
    weapon_10_round: 2.44,
    artifact_1: 'Proto Punk, Shockstar Disco',
    artifact_1_1: 'Proto Punk',
    artifact_1_2: 'Shockstar Disco',
    artifact_1_3: '',
    artifact_1_app: 82.78,
    artifact_1_round: 2.88,
    artifact_2: 'Proto Punk, Swing Jazz',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 8.02,
    artifact_2_round: 2.88,
    artifact_3: 'Freedom Blues, Shockstar Disco',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Shockstar Disco',
    artifact_3_3: '',
    artifact_3_app: 1.52,
    artifact_3_round: 2.91,
    artifact_4: 'Proto Punk, Flex',
    artifact_4_1: 'Proto Punk',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 0.97,
    artifact_4_round: 2.82,
    artifact_5: 'Proto Punk, Woodpecker Electro',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 0.62,
    artifact_5_round: 2.55,
    artifact_6: 'Freedom Blues, Swing Jazz',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 0.58,
    artifact_6_round: 2.86,
    artifact_7: 'Proto Punk, Fanged Metal',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Fanged Metal',
    artifact_7_3: '',
    artifact_7_app: 0.55,
    artifact_7_round: 3.0,
    artifact_8: 'Shockstar Disco, Swing Jazz',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.47,
    artifact_8_round: 2.88,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 0.39,
    artifact_9_round: 2.78,
    artifact_10: 'Proto Punk, Freedom Blues',
    artifact_10_1: 'Proto Punk',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.39,
    artifact_10_round: 2.71,
    app_0: 89.56,
    round_0: 2.88,
    app_1: 6.23,
    round_1: 2.96,
    app_2: 2.92,
    round_2: 3.0,
    app_3: 0.31,
    round_3: 3.0,
    app_4: 0.04,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.93,
    round_6: 3.0,
    cons_avg: 0.19,
    sample: 2567,
    sample_app_flat: 2030
  },
  {
    char: 'Piper',
    app_rate: 20.83,
    avg_round: 2.87,
    std_dev_round: 0.38,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 2.03,
    diff_rounds: -0.02,
    weapon_1: 'Roaring Ride',
    weapon_1_app: 78.73,
    weapon_1_round: 2.87,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 5.84,
    weapon_2_round: 2.81,
    weapon_3: 'Weeping Gemini',
    weapon_3_app: 5.24,
    weapon_3_round: 2.96,
    weapon_4: 'Fusion Compiler',
    weapon_4_app: 5.24,
    weapon_4_round: 2.83,
    weapon_5: 'Electro-Lip Gloss',
    weapon_5_app: 1.48,
    weapon_5_round: 2.85,
    weapon_6: 'Sharpened Stinger',
    weapon_6_app: 1.09,
    weapon_6_round: 3.0,
    weapon_7: '[Magnetic Storm] Alpha',
    weapon_7_app: 0.2,
    weapon_7_round: 3.0,
    weapon_8: 'Flamemaker Shaker',
    weapon_8_app: 0.2,
    weapon_8_round: 2.5,
    weapon_9: '[Magnetic Storm] Charlie',
    weapon_9_app: 0.1,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 29.97,
    artifact_1_round: 2.91,
    artifact_2: 'Freedom Blues, Fanged Metal',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 28.09,
    artifact_2_round: 2.83,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 13.65,
    artifact_3_round: 2.88,
    artifact_4: 'Fanged Metal, Chaos Jazz',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Chaos Jazz',
    artifact_4_3: '',
    artifact_4_app: 5.74,
    artifact_4_round: 2.88,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 4.55,
    artifact_5_round: 2.9,
    artifact_6: 'Fanged Metal, Swing Jazz',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.37,
    artifact_6_round: 2.88,
    artifact_7: 'Fanged Metal, Hormone Punk',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.48,
    artifact_7_round: 3.0,
    artifact_8: 'Freedom Blues, Flex',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.48,
    artifact_8_round: 2.69,
    artifact_9: 'Fanged Metal, Flex',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 1.38,
    artifact_9_round: 3.0,
    artifact_10: 'Freedom Blues, Hormone Punk',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 1.19,
    artifact_10_round: 3.0,
    app_0: 2.67,
    round_0: 2.72,
    app_1: 6.13,
    round_1: 2.73,
    app_2: 9.3,
    round_2: 2.84,
    app_3: 11.57,
    round_3: 2.89,
    app_4: 12.17,
    round_4: 2.88,
    app_5: 10.78,
    round_5: 2.86,
    app_6: 47.38,
    round_6: 2.9,
    cons_avg: 4.46,
    sample: 1011,
    sample_app_flat: 901
  },
  {
    char: 'Rina',
    app_rate: 19.72,
    avg_round: 2.85,
    std_dev_round: 0.41,
    role: 'Support',
    rarity: 'Standard S',
    diff: 6.18,
    diff_rounds: -0.03,
    weapon_1: 'Slice of Time',
    weapon_1_app: 51.41,
    weapon_1_round: 2.82,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 42.32,
    weapon_2_round: 2.91,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 2.82,
    weapon_3_round: 2.88,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 1.57,
    weapon_4_round: 2.69,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 0.94,
    weapon_5_round: 2.67,
    weapon_6: '[Reverb] Mark I',
    weapon_6_app: 0.21,
    weapon_6_round: 3.0,
    weapon_7: 'The Vault',
    weapon_7_app: 0.21,
    weapon_7_round: 3.0,
    weapon_8: '[Reverb] Mark III',
    weapon_8_app: 0.1,
    weapon_8_round: 3.0,
    weapon_9: 'Fusion Compiler',
    weapon_9_app: 0.1,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Puffer Electro',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Puffer Electro',
    artifact_1_3: '',
    artifact_1_app: 56.43,
    artifact_1_round: 2.88,
    artifact_2: 'Freedom Blues, Puffer Electro',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 12.43,
    artifact_2_round: 2.88,
    artifact_3: 'Swing Jazz, Freedom Blues',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 4.81,
    artifact_3_round: 2.69,
    artifact_4: 'Freedom Blues, Swing Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 3.87,
    artifact_4_round: 2.81,
    artifact_5: 'Swing Jazz, Flex',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.19,
    artifact_5_round: 2.81,
    artifact_6: 'Freedom Blues, Thunder Metal',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Thunder Metal',
    artifact_6_3: '',
    artifact_6_app: 1.57,
    artifact_6_round: 2.43,
    artifact_7: 'Swing Jazz, Thunder Metal',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Thunder Metal',
    artifact_7_3: '',
    artifact_7_app: 1.46,
    artifact_7_round: 2.7,
    artifact_8: 'Puffer Electro, Swing Jazz',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.15,
    artifact_8_round: 2.78,
    artifact_9: 'Puffer Electro, Swing Jazz, Flex',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: 'Flex',
    artifact_9_app: 1.04,
    artifact_9_round: 2.88,
    artifact_10: 'AP +30, Puffer Electro, Swing Jazz',
    artifact_10_1: 'AP +30',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: 'Swing Jazz',
    artifact_10_app: 0.94,
    artifact_10_round: 3.0,
    app_0: 52.98,
    round_0: 2.86,
    app_1: 33.75,
    round_1: 2.84,
    app_2: 9.61,
    round_2: 2.84,
    app_3: 2.51,
    round_3: 2.79,
    app_4: 0.73,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.42,
    round_6: 1.0,
    cons_avg: 0.66,
    sample: 957,
    sample_app_flat: 766
  },
  {
    char: 'grace-howard',
    app_rate: 9.58,
    avg_round: 2.82,
    std_dev_round: 0.44,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: 2.82,
    diff_rounds: -0.07,
    weapon_1: 'Fusion Compiler',
    weapon_1_app: 36.56,
    weapon_1_round: 2.87,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 24.95,
    weapon_2_round: 2.68,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 20.22,
    weapon_3_round: 2.87,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 5.38,
    weapon_4_round: 2.83,
    weapon_5: 'Roaring Ride',
    weapon_5_app: 2.15,
    weapon_5_round: 2.6,
    weapon_6: 'Timeweaver',
    weapon_6_app: 1.29,
    weapon_6_round: 2.83,
    weapon_7: '[Magnetic Storm] Bravo',
    weapon_7_app: 0.65,
    weapon_7_round: 3.0,
    weapon_8: '[Magnetic Storm] Alpha',
    weapon_8_app: 0.65,
    weapon_8_round: 3.0,
    weapon_9: 'Flamemaker Shaker',
    weapon_9_app: 0.22,
    weapon_9_round: 3.0,
    weapon_10: '[Magnetic Storm] Charlie',
    weapon_10_app: 0.22,
    weapon_10_round: 3.0,
    artifact_1: 'Freedom Blues, Thunder Metal',
    artifact_1_1: 'Freedom Blues',
    artifact_1_2: 'Thunder Metal',
    artifact_1_3: '',
    artifact_1_app: 30.75,
    artifact_1_round: 2.75,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 21.72,
    artifact_2_round: 2.82,
    artifact_3: 'Chaos Jazz, Freedom Blues',
    artifact_3_1: 'Chaos Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 6.88,
    artifact_3_round: 2.93,
    artifact_4: 'Freedom Blues, Swing Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 6.67,
    artifact_4_round: 2.7,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 3.44,
    artifact_5_round: 2.86,
    artifact_6: 'Chaos Jazz, Thunder Metal',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Thunder Metal',
    artifact_6_3: '',
    artifact_6_app: 2.8,
    artifact_6_round: 3.0,
    artifact_7: 'Thunder Metal, Chaos Jazz',
    artifact_7_1: 'Thunder Metal',
    artifact_7_2: 'Chaos Jazz',
    artifact_7_3: '',
    artifact_7_app: 1.94,
    artifact_7_round: 2.88,
    artifact_8: 'Thunder Metal, Swing Jazz',
    artifact_8_1: 'Thunder Metal',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.72,
    artifact_8_round: 3.0,
    artifact_9: 'Freedom Blues, Flex',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 1.51,
    artifact_9_round: 2.29,
    artifact_10: 'AP +30, AP +30, Thunder Metal',
    artifact_10_1: 'AP +30',
    artifact_10_2: 'AP +30',
    artifact_10_3: 'Thunder Metal',
    artifact_10_app: 1.51,
    artifact_10_round: 3.0,
    app_0: 61.94,
    round_0: 2.81,
    app_1: 28.17,
    round_1: 2.82,
    app_2: 8.39,
    round_2: 2.87,
    app_3: 1.08,
    round_3: 3.0,
    app_4: 0.22,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.22,
    round_6: 3.0,
    cons_avg: 0.5,
    sample: 465,
    sample_app_flat: 414
  },
  {
    char: 'Soukaku',
    app_rate: 31.27,
    avg_round: 2.8,
    std_dev_round: 0.47,
    role: 'Support',
    rarity: 'A',
    diff: -12.94,
    diff_rounds: -0.06,
    weapon_1: 'Bashful Demon',
    weapon_1_app: 93.02,
    weapon_1_round: 2.82,
    weapon_2: 'Slice of Time',
    weapon_2_app: 2.5,
    weapon_2_round: 2.51,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 1.38,
    weapon_3_round: 2.75,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 0.86,
    weapon_4_round: 2.36,
    weapon_5: 'Weeping Cradle',
    weapon_5_app: 0.79,
    weapon_5_round: 2.89,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 0.53,
    weapon_6_round: 2.5,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 0.33,
    weapon_7_round: 2.8,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.26,
    weapon_8_round: 2.33,
    weapon_9: 'The Vault',
    weapon_9_app: 0.13,
    weapon_9_round: 3.0,
    weapon_10: 'The Brimstone',
    weapon_10_app: 0.07,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 52.04,
    artifact_1_round: 2.81,
    artifact_2: 'Swing Jazz, Polar Metal',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 10.47,
    artifact_2_round: 2.78,
    artifact_3: 'Swing Jazz, Flex',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 4.28,
    artifact_3_round: 2.79,
    artifact_4: 'Swing Jazz, Woodpecker Electro',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 4.08,
    artifact_4_round: 2.85,
    artifact_5: 'Polar Metal, Woodpecker Electro',
    artifact_5_1: 'Polar Metal',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 2.9,
    artifact_5_round: 2.82,
    artifact_6: 'Swing Jazz, Freedom Blues',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 2.44,
    artifact_6_round: 2.95,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.04,
    artifact_7_round: 2.8,
    artifact_8: 'Hormone Punk, Swing Jazz',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.78,
    artifact_8_round: 2.89,
    artifact_9: 'Hormone Punk, Polar Metal',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Polar Metal',
    artifact_9_3: '',
    artifact_9_app: 1.71,
    artifact_9_round: 2.6,
    artifact_10: 'Freedom Blues, Polar Metal',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Polar Metal',
    artifact_10_3: '',
    artifact_10_app: 1.65,
    artifact_10_round: 2.9,
    app_0: 0.33,
    round_0: 1.8,
    app_1: 0.53,
    round_1: 3.0,
    app_2: 2.04,
    round_2: 2.83,
    app_3: 2.9,
    round_3: 2.71,
    app_4: 3.89,
    round_4: 2.78,
    app_5: 7.18,
    round_5: 2.83,
    app_6: 83.14,
    round_6: 2.81,
    cons_avg: 5.64,
    sample: 1518,
    sample_app_flat: 1109
  },
  {
    char: 'Nekomata',
    app_rate: 3.71,
    avg_round: 2.8,
    std_dev_round: 0.44,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -1.57,
    diff_rounds: -0.02,
    weapon_1: 'Steel Cushion',
    weapon_1_app: 47.78,
    weapon_1_round: 2.83,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 26.67,
    weapon_2_round: 2.79,
    weapon_3: 'Gilded Blossom',
    weapon_3_app: 9.44,
    weapon_3_round: 2.76,
    weapon_4: 'The Brimstone',
    weapon_4_app: 8.33,
    weapon_4_round: 2.79,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 5.0,
    weapon_5_round: 3.0,
    weapon_6: 'Street Superstar',
    weapon_6_app: 2.22,
    weapon_6_round: 2.0,
    weapon_7: 'Housekeeper',
    weapon_7_app: 0.56,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 46.11,
    artifact_1_round: 2.76,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 27.78,
    artifact_2_round: 2.86,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 6.67,
    artifact_3_round: 3.0,
    artifact_4: 'Woodpecker Electro, Puffer Electro',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 2.22,
    artifact_4_round: 2.67,
    artifact_5: 'Woodpecker Electro, Hormone Punk',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 2.22,
    artifact_5_round: 3.0,
    artifact_6: 'Hormone Punk, Fanged Metal',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Fanged Metal',
    artifact_6_3: '',
    artifact_6_app: 2.22,
    artifact_6_round: 2.5,
    artifact_7: 'Woodpecker Electro, Flex',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 2.22,
    artifact_7_round: 2.67,
    artifact_8: 'Woodpecker Electro, Swing Jazz',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.11,
    artifact_8_round: 2.5,
    artifact_9: 'Puffer Electro, Fanged Metal',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Fanged Metal',
    artifact_9_3: '',
    artifact_9_app: 1.11,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Flex',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 1.11,
    artifact_10_round: 3.0,
    app_0: 55.56,
    round_0: 2.82,
    app_1: 31.67,
    round_1: 2.84,
    app_2: 10.0,
    round_2: 2.62,
    app_3: 1.11,
    round_3: 3.0,
    app_4: 1.11,
    round_4: 2.5,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.56,
    round_6: 1.0,
    cons_avg: 0.63,
    sample: 180,
    sample_app_flat: 162
  },
  {
    char: 'Lycaon',
    app_rate: 20.35,
    avg_round: 2.8,
    std_dev_round: 0.47,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -10.94,
    diff_rounds: -0.07,
    weapon_1: 'The Restrained',
    weapon_1_app: 32.39,
    weapon_1_round: 2.81,
    weapon_2: 'Steam Oven',
    weapon_2_app: 24.09,
    weapon_2_round: 2.8,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 18.83,
    weapon_3_round: 2.72,
    weapon_4: 'Hellfire Gears',
    weapon_4_app: 15.69,
    weapon_4_round: 2.86,
    weapon_5: 'Six Shooter',
    weapon_5_app: 5.36,
    weapon_5_round: 2.77,
    weapon_6: '[Vortex] Arrow',
    weapon_6_app: 1.21,
    weapon_6_round: 2.75,
    weapon_7: 'Demara Battery Mark II',
    weapon_7_app: 1.21,
    weapon_7_round: 2.86,
    weapon_8: '[Vortex] Revolver',
    weapon_8_app: 0.4,
    weapon_8_round: 3.0,
    weapon_9: 'Spring Embrace',
    weapon_9_app: 0.1,
    weapon_9_round: 2.0,
    weapon_10: 'Ice-Jade Teapot',
    weapon_10_app: 0.1,
    weapon_10_round: 3.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 42.31,
    artifact_1_round: 2.83,
    artifact_2: 'Shockstar Disco, Polar Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 34.31,
    artifact_2_round: 2.74,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 6.98,
    artifact_3_round: 2.78,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.25,
    artifact_4_round: 2.79,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 1.62,
    artifact_5_round: 2.62,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.62,
    artifact_6_round: 2.88,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.52,
    artifact_7_round: 3.0,
    artifact_8: 'Freedom Blues, Shockstar Disco',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.21,
    artifact_8_round: 2.91,
    artifact_9: 'Shockstar Disco, Puffer Electro',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.11,
    artifact_9_round: 3.0,
    artifact_10: 'Polar Metal, Shockstar Disco',
    artifact_10_1: 'Polar Metal',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.91,
    artifact_10_round: 2.43,
    app_0: 64.07,
    round_0: 2.8,
    app_1: 27.23,
    round_1: 2.8,
    app_2: 6.58,
    round_2: 2.76,
    app_3: 1.52,
    round_3: 2.89,
    app_4: 0.61,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.47,
    sample: 988,
    sample_app_flat: 723
  },
  {
    char: 'soldier-11',
    app_rate: 12.65,
    avg_round: 2.8,
    std_dev_round: 0.47,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: 1.84,
    diff_rounds: -0.02,
    weapon_1: 'The Brimstone',
    weapon_1_app: 37.46,
    weapon_1_round: 2.92,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 33.55,
    weapon_2_round: 2.76,
    weapon_3: 'Gilded Blossom',
    weapon_3_app: 11.56,
    weapon_3_round: 2.63,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 11.24,
    weapon_4_round: 2.74,
    weapon_5: 'Steel Cushion',
    weapon_5_app: 2.61,
    weapon_5_round: 2.73,
    weapon_6: 'Street Superstar',
    weapon_6_app: 2.44,
    weapon_6_round: 2.73,
    weapon_7: 'Drill Rig - Red Axis',
    weapon_7_app: 0.16,
    weapon_7_round: 3.0,
    weapon_8: 'Deep Sea Visitor',
    weapon_8_app: 0.16,
    weapon_8_round: 3.0,
    weapon_9: 'Riot Suppressor Mark VI',
    weapon_9_app: 0.16,
    weapon_9_round: 3.0,
    weapon_10: '[Lunar] Noviluna',
    weapon_10_app: 0.16,
    weapon_10_round: 3.0,
    artifact_1: 'Inferno Metal, Woodpecker Electro',
    artifact_1_1: 'Inferno Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 77.2,
    artifact_1_round: 2.79,
    artifact_2: 'Inferno Metal, Puffer Electro',
    artifact_2_1: 'Inferno Metal',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 6.68,
    artifact_2_round: 2.82,
    artifact_3: 'Woodpecker Electro, Inferno Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Inferno Metal',
    artifact_3_3: '',
    artifact_3_app: 4.23,
    artifact_3_round: 2.86,
    artifact_4: 'Inferno Metal, Hormone Punk',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 2.61,
    artifact_4_round: 2.87,
    artifact_5: 'Inferno Metal, Swing Jazz',
    artifact_5_1: 'Inferno Metal',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 2.28,
    artifact_5_round: 2.86,
    artifact_6: 'Inferno Metal, Flex',
    artifact_6_1: 'Inferno Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.95,
    artifact_6_round: 2.88,
    artifact_7: 'Woodpecker Electro, Puffer Electro',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 0.49,
    artifact_7_round: 3.0,
    artifact_8: 'Hormone Punk, Woodpecker Electro',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.49,
    artifact_8_round: 3.0,
    artifact_9: 'Woodpecker Electro, Hormone Punk',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.49,
    artifact_9_round: 2.67,
    artifact_10: 'Puffer Electro, Woodpecker Electro',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 0.33,
    artifact_10_round: 3.0,
    app_0: 61.24,
    round_0: 2.78,
    app_1: 31.43,
    round_1: 2.83,
    app_2: 5.7,
    round_2: 2.8,
    app_3: 1.47,
    round_3: 3.0,
    app_4: 0.16,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.48,
    sample: 614,
    sample_app_flat: 558
  },
  {
    char: 'Ellen',
    app_rate: 34.88,
    avg_round: 2.8,
    std_dev_round: 0.46,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -15.33,
    diff_rounds: -0.07,
    weapon_1: 'Deep Sea Visitor',
    weapon_1_app: 71.77,
    weapon_1_round: 2.87,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 9.69,
    weapon_2_round: 2.61,
    weapon_3: 'The Brimstone',
    weapon_3_app: 6.32,
    weapon_3_round: 2.76,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 4.43,
    weapon_4_round: 2.71,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 3.43,
    weapon_5_round: 2.6,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 2.89,
    weapon_6_round: 2.83,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.65,
    weapon_7_round: 2.78,
    weapon_8: 'Riot Suppressor Mark VI',
    weapon_8_app: 0.35,
    weapon_8_round: 3.0,
    weapon_9: 'Housekeeper',
    weapon_9_app: 0.18,
    weapon_9_round: 2.33,
    weapon_10: '[Lunar] Noviluna',
    weapon_10_app: 0.06,
    weapon_10_round: 3.0,
    artifact_1: 'Polar Metal, Woodpecker Electro',
    artifact_1_1: 'Polar Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 56.82,
    artifact_1_round: 2.78,
    artifact_2: 'Woodpecker Electro, Polar Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 12.11,
    artifact_2_round: 2.87,
    artifact_3: 'Woodpecker Electro, Puffer Electro',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 10.22,
    artifact_3_round: 2.79,
    artifact_4: 'Polar Metal, Puffer Electro',
    artifact_4_1: 'Polar Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 9.81,
    artifact_4_round: 2.9,
    artifact_5: 'Polar Metal, Hormone Punk',
    artifact_5_1: 'Polar Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 3.19,
    artifact_5_round: 2.87,
    artifact_6: 'Polar Metal, Flex',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.01,
    artifact_6_round: 2.88,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.95,
    artifact_7_round: 2.72,
    artifact_8: 'Puffer Electro, Woodpecker Electro',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.71,
    artifact_8_round: 3.0,
    artifact_9: 'Polar Metal, Freedom Blues',
    artifact_9_1: 'Polar Metal',
    artifact_9_2: 'Freedom Blues',
    artifact_9_3: '',
    artifact_9_app: 0.71,
    artifact_9_round: 2.7,
    artifact_10: 'Woodpecker Electro, Flex',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 0.59,
    artifact_10_round: 2.75,
    app_0: 72.12,
    round_0: 2.8,
    app_1: 15.36,
    round_1: 2.93,
    app_2: 7.91,
    round_2: 2.94,
    app_3: 1.0,
    round_3: 2.94,
    app_4: 0.18,
    round_4: 3.0,
    app_5: 0.06,
    round_5: 3.0,
    app_6: 3.37,
    round_6: 3.0,
    cons_avg: 0.55,
    sample: 1693,
    sample_app_flat: 1193
  },
  {
    char: 'anby-demara',
    app_rate: 13.43,
    avg_round: 2.78,
    std_dev_round: 0.49,
    role: 'Stun',
    rarity: 'A',
    diff: -0.51,
    diff_rounds: -0.07,
    weapon_1: 'Demara Battery Mark II',
    weapon_1_app: 63.04,
    weapon_1_round: 2.76,
    weapon_2: 'Steam Oven',
    weapon_2_app: 14.26,
    weapon_2_round: 2.83,
    weapon_3: 'Hellfire Gears',
    weapon_3_app: 10.12,
    weapon_3_round: 2.79,
    weapon_4: 'The Restrained',
    weapon_4_app: 7.82,
    weapon_4_round: 2.88,
    weapon_5: 'Precious Fossilized Core',
    weapon_5_app: 2.76,
    weapon_5_round: 2.65,
    weapon_6: 'Ice-Jade Teapot',
    weapon_6_app: 0.61,
    weapon_6_round: 3.0,
    weapon_7: 'Six Shooter',
    weapon_7_app: 0.46,
    weapon_7_round: 2.67,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.15,
    weapon_8_round: 3.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 53.68,
    artifact_1_round: 2.82,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 20.4,
    artifact_2_round: 2.68,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 9.2,
    artifact_3_round: 2.61,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.29,
    artifact_4_round: 2.75,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 2.61,
    artifact_5_round: 2.87,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.84,
    artifact_6_round: 3.0,
    artifact_7: 'Shockstar Disco, Puffer Electro',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 0.61,
    artifact_7_round: 3.0,
    artifact_8: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: 'Flex',
    artifact_8_app: 0.61,
    artifact_8_round: 3.0,
    artifact_9: 'Shockstar Disco, Thunder Metal, Woodpecker Electro',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: 'Woodpecker Electro',
    artifact_9_app: 0.46,
    artifact_9_round: 3.0,
    artifact_10: 'Shockstar Disco, Thunder Metal, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Thunder Metal',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.46,
    artifact_10_round: 2.67,
    app_0: 1.53,
    round_0: 2.2,
    app_1: 2.76,
    round_1: 2.53,
    app_2: 5.06,
    round_2: 2.57,
    app_3: 5.52,
    round_3: 2.72,
    app_4: 5.83,
    round_4: 2.63,
    app_5: 7.06,
    round_5: 2.82,
    app_6: 72.24,
    round_6: 2.84,
    cons_avg: 5.21,
    sample: 652,
    sample_app_flat: 562
  },
  {
    char: 'Koleda',
    app_rate: 7.5,
    avg_round: 2.77,
    std_dev_round: 0.49,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -1.01,
    diff_rounds: -0.02,
    weapon_1: 'Hellfire Gears',
    weapon_1_app: 35.44,
    weapon_1_round: 2.81,
    weapon_2: 'Steam Oven',
    weapon_2_app: 34.07,
    weapon_2_round: 2.74,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 14.01,
    weapon_3_round: 2.69,
    weapon_4: 'The Restrained',
    weapon_4_app: 6.59,
    weapon_4_round: 2.9,
    weapon_5: 'Six Shooter',
    weapon_5_app: 5.22,
    weapon_5_round: 2.79,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 2.47,
    weapon_6_round: 2.67,
    weapon_7: '[Vortex] Arrow',
    weapon_7_app: 1.1,
    weapon_7_round: 2.75,
    weapon_8: '[Vortex] Revolver',
    weapon_8_app: 0.55,
    weapon_8_round: 2.5,
    weapon_9: 'Ice-Jade Teapot',
    weapon_9_app: 0.27,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 67.58,
    artifact_1_round: 2.8,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 8.24,
    artifact_2_round: 2.75,
    artifact_3: 'Shockstar Disco, Flex',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 6.32,
    artifact_3_round: 2.6,
    artifact_4: 'Shockstar Disco, Freedom Blues',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 5.49,
    artifact_4_round: 2.54,
    artifact_5: 'Shockstar Disco, Woodpecker Electro',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 4.12,
    artifact_5_round: 2.77,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.1,
    artifact_6_round: 2.75,
    artifact_7: 'Proto Punk, Shockstar Disco',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 0.82,
    artifact_7_round: 3.0,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.82,
    artifact_8_round: 3.0,
    artifact_9: 'Shockstar Disco, Puffer Electro',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 0.55,
    artifact_9_round: 2.5,
    artifact_10: 'Freedom Blues, Shockstar Disco',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.55,
    artifact_10_round: 2.5,
    app_0: 62.36,
    round_0: 2.76,
    app_1: 24.73,
    round_1: 2.76,
    app_2: 10.16,
    round_2: 2.82,
    app_3: 2.75,
    round_3: 2.8,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.53,
    sample: 364,
    sample_app_flat: 333
  },
  {
    char: 'Anton',
    app_rate: 4.61,
    avg_round: 2.73,
    std_dev_round: 0.56,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 2.58,
    diff_rounds: 0.0,
    weapon_1: 'Drill Rig - Red Axis',
    weapon_1_app: 74.55,
    weapon_1_round: 2.69,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 8.93,
    weapon_2_round: 2.84,
    weapon_3: 'Street Superstar',
    weapon_3_app: 5.8,
    weapon_3_round: 2.83,
    weapon_4: 'The Brimstone',
    weapon_4_app: 5.36,
    weapon_4_round: 2.89,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 2.23,
    weapon_5_round: 3.0,
    weapon_6: 'Gilded Blossom',
    weapon_6_app: 1.79,
    weapon_6_round: 2.75,
    weapon_7: 'Starlight Engine Replica',
    weapon_7_app: 0.89,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Thunder Metal, Woodpecker Electro',
    artifact_1_1: 'Thunder Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 75.89,
    artifact_1_round: 2.79,
    artifact_2: 'Thunder Metal, Swing Jazz',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 7.14,
    artifact_2_round: 2.67,
    artifact_3: 'Woodpecker Electro, Thunder Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Thunder Metal',
    artifact_3_3: '',
    artifact_3_app: 2.68,
    artifact_3_round: 1.83,
    artifact_4: 'Thunder Metal, Puffer Electro',
    artifact_4_1: 'Thunder Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 2.68,
    artifact_4_round: 2.67,
    artifact_5: 'Thunder Metal, Flex',
    artifact_5_1: 'Thunder Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.23,
    artifact_5_round: 2.8,
    artifact_6: 'Thunder Metal, Woodpecker Electro, Flex',
    artifact_6_1: 'Thunder Metal',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: 'Flex',
    artifact_6_app: 1.34,
    artifact_6_round: 2.67,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.34,
    artifact_7_round: 2.67,
    artifact_8: 'Woodpecker Electro, Flex',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 0.89,
    artifact_8_round: 2.5,
    artifact_9: 'Thunder Metal, Hormone Punk',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.89,
    artifact_9_round: 3.0,
    artifact_10: 'Thunder Metal, Flex',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 0.45,
    artifact_10_round: 1.0,
    app_0: 0.89,
    round_0: 1.5,
    app_1: 3.12,
    round_1: 1.86,
    app_2: 2.23,
    round_2: 2.75,
    app_3: 3.12,
    round_3: 2.67,
    app_4: 3.12,
    round_4: 2.86,
    app_5: 5.8,
    round_5: 2.62,
    app_6: 81.7,
    round_6: 2.79,
    cons_avg: 5.49,
    sample: 224,
    sample_app_flat: 202
  },
  {
    char: 'Corin',
    app_rate: 2.45,
    avg_round: 2.67,
    std_dev_round: 0.62,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -1.05,
    diff_rounds: -0.1,
    weapon_1: 'Housekeeper',
    weapon_1_app: 61.34,
    weapon_1_round: 2.65,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 18.49,
    weapon_2_round: 2.88,
    weapon_3: 'Gilded Blossom',
    weapon_3_app: 10.08,
    weapon_3_round: 2.17,
    weapon_4: 'Starlight Engine',
    weapon_4_app: 2.52,
    weapon_4_round: 3.0,
    weapon_5: 'The Brimstone',
    weapon_5_app: 1.68,
    weapon_5_round: 3.0,
    weapon_6: 'Cannon Rotor',
    weapon_6_app: 1.68,
    weapon_6_round: 3.0,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.84,
    weapon_7_round: 3.0,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.84,
    weapon_8_round: 3.0,
    weapon_9: 'Deep Sea Visitor',
    weapon_9_app: 0.84,
    weapon_9_round: 3.0,
    weapon_10: '[Lunar] Decrescent',
    weapon_10_app: 0.84,
    weapon_10_round: 3.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 39.5,
    artifact_1_round: 2.52,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 13.45,
    artifact_2_round: 2.64,
    artifact_3: 'Hormone Punk, Woodpecker Electro',
    artifact_3_1: 'Hormone Punk',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 6.72,
    artifact_3_round: 3.0,
    artifact_4: 'Fanged Metal, Hormone Punk',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 5.88,
    artifact_4_round: 3.0,
    artifact_5: 'Hormone Punk, Fanged Metal',
    artifact_5_1: 'Hormone Punk',
    artifact_5_2: 'Fanged Metal',
    artifact_5_3: '',
    artifact_5_app: 5.88,
    artifact_5_round: 2.5,
    artifact_6: 'Puffer Electro, Fanged Metal',
    artifact_6_1: 'Puffer Electro',
    artifact_6_2: 'Fanged Metal',
    artifact_6_3: '',
    artifact_6_app: 4.2,
    artifact_6_round: 2.33,
    artifact_7: 'Puffer Electro, Woodpecker Electro',
    artifact_7_1: 'Puffer Electro',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 2.52,
    artifact_7_round: 2.0,
    artifact_8: 'Fanged Metal, Flex',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 2.52,
    artifact_8_round: 2.67,
    artifact_9: 'Woodpecker Electro, Puffer Electro',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 2.52,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: 'Woodpecker Electro',
    artifact_10_app: 2.52,
    artifact_10_round: 3.0,
    app_0: 4.2,
    round_0: 2.0,
    app_1: 0.84,
    round_1: 3.0,
    app_2: 7.56,
    round_2: 2.38,
    app_3: 6.72,
    round_3: 2.12,
    app_4: 6.72,
    round_4: 2.88,
    app_5: 11.76,
    round_5: 2.67,
    app_6: 62.18,
    round_6: 2.8,
    cons_avg: 4.95,
    sample: 119,
    sample_app_flat: 102
  },
  {
    char: 'billy-kid',
    app_rate: 1.46,
    avg_round: 2.64,
    std_dev_round: 0.6,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.12,
    diff_rounds: -0.11,
    weapon_1: 'Starlight Engine Replica',
    weapon_1_app: 61.97,
    weapon_1_round: 2.63,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 12.68,
    weapon_2_round: 2.5,
    weapon_3: 'The Brimstone',
    weapon_3_app: 11.27,
    weapon_3_round: 2.57,
    weapon_4: 'Street Superstar',
    weapon_4_app: 5.63,
    weapon_4_round: 2.75,
    weapon_5: 'Starlight Engine',
    weapon_5_app: 5.63,
    weapon_5_round: 2.75,
    weapon_6: '',
    weapon_6_app: 0.0,
    weapon_6_round: 1.0,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Woodpecker Electro, Fanged Metal',
    artifact_1_1: 'Woodpecker Electro',
    artifact_1_2: 'Fanged Metal',
    artifact_1_3: '',
    artifact_1_app: 25.35,
    artifact_1_round: 2.88,
    artifact_2: 'Fanged Metal, Woodpecker Electro',
    artifact_2_1: 'Fanged Metal',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 19.72,
    artifact_2_round: 2.57,
    artifact_3: 'Puffer Electro, Woodpecker Electro',
    artifact_3_1: 'Puffer Electro',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 15.49,
    artifact_3_round: 2.4,
    artifact_4: 'Woodpecker Electro, Puffer Electro',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 11.27,
    artifact_4_round: 2.71,
    artifact_5: 'Puffer Electro, Fanged Metal',
    artifact_5_1: 'Puffer Electro',
    artifact_5_2: 'Fanged Metal',
    artifact_5_3: '',
    artifact_5_app: 5.63,
    artifact_5_round: 2.25,
    artifact_6: 'Woodpecker Electro, Hormone Punk',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 4.23,
    artifact_6_round: 2.5,
    artifact_7: 'Fanged Metal, Hormone Punk',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 2.82,
    artifact_7_round: 2.5,
    artifact_8: 'Hormone Punk, Fanged Metal',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Fanged Metal',
    artifact_8_3: '',
    artifact_8_app: 2.82,
    artifact_8_round: 2.0,
    artifact_9: 'Woodpecker Electro, Flex',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 2.82,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Woodpecker Electro, Flex',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: 'Flex',
    artifact_10_app: 1.41,
    artifact_10_round: 3.0,
    app_0: 1.41,
    round_0: 3.0,
    app_1: 2.82,
    round_1: 2.0,
    app_2: 0.0,
    round_2: 1.0,
    app_3: 1.41,
    round_3: 3.0,
    app_4: 7.04,
    round_4: 2.4,
    app_5: 9.86,
    round_5: 2.57,
    app_6: 77.46,
    round_6: 2.68,
    cons_avg: 5.49,
    sample: 71,
    sample_app_flat: 66
  },
  {
    char: 'Ben',
    app_rate: 1.92,
    avg_round: 2.64,
    std_dev_round: 0.62,
    role: 'Stun',
    rarity: 'A',
    diff: -0.11,
    diff_rounds: -0.1,
    weapon_1: 'Big Cylinder',
    weapon_1_app: 77.42,
    weapon_1_round: 2.65,
    weapon_2: 'Bunny Band',
    weapon_2_app: 9.68,
    weapon_2_round: 2.44,
    weapon_3: 'Original Transmorpher',
    weapon_3_app: 7.53,
    weapon_3_round: 2.57,
    weapon_4: 'Tusks of Fury',
    weapon_4_app: 3.23,
    weapon_4_round: 3.0,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 1.08,
    weapon_5_round: 3.0,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 1.08,
    weapon_6_round: 3.0,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Proto Punk, Soul Rock',
    artifact_1_1: 'Proto Punk',
    artifact_1_2: 'Soul Rock',
    artifact_1_3: '',
    artifact_1_app: 16.13,
    artifact_1_round: 2.67,
    artifact_2: 'Swing Jazz, Soul Rock',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Soul Rock',
    artifact_2_3: '',
    artifact_2_app: 15.05,
    artifact_2_round: 2.93,
    artifact_3: 'Shockstar Disco, Swing Jazz',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 8.6,
    artifact_3_round: 2.62,
    artifact_4: 'Soul Rock, Swing Jazz',
    artifact_4_1: 'Soul Rock',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 8.6,
    artifact_4_round: 2.25,
    artifact_5: 'Inferno Metal, Soul Rock',
    artifact_5_1: 'Inferno Metal',
    artifact_5_2: 'Soul Rock',
    artifact_5_3: '',
    artifact_5_app: 5.38,
    artifact_5_round: 2.75,
    artifact_6: 'Shockstar Disco, Soul Rock',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Soul Rock',
    artifact_6_3: '',
    artifact_6_app: 4.3,
    artifact_6_round: 2.5,
    artifact_7: 'Proto Punk, Shockstar Disco',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 3.23,
    artifact_7_round: 3.0,
    artifact_8: 'Inferno Metal, Shockstar Disco, Soul Rock',
    artifact_8_1: 'Inferno Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: 'Soul Rock',
    artifact_8_app: 2.15,
    artifact_8_round: 2.5,
    artifact_9: 'Proto Punk, Swing Jazz',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: '',
    artifact_9_app: 2.15,
    artifact_9_round: 2.5,
    artifact_10: 'Inferno Metal, Woodpecker Electro',
    artifact_10_1: 'Inferno Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 2.15,
    artifact_10_round: 3.0,
    app_0: 2.15,
    round_0: 1.0,
    app_1: 3.23,
    round_1: 2.33,
    app_2: 7.53,
    round_2: 2.29,
    app_3: 5.38,
    round_3: 2.4,
    app_4: 6.45,
    round_4: 2.5,
    app_5: 11.83,
    round_5: 2.73,
    app_6: 63.44,
    round_6: 2.79,
    cons_avg: 5.0,
    sample: 93,
    sample_app_flat: 90
  }
];
